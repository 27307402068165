const api_common = "api-1-0/et87yzsxcd78615231asdz8xc9a1f3g/common/"

const prod = {
    url: 'https://api.observatorio.analisepoliticaemsaude.org/' + api_common
};

const dev = {
    url: 'http://0.0.0.0:8000/' + api_common
};

const local = {
    url: 'http://127.0.0.1:8000/' + api_common
};

// export const API = process.env.NODE_ENV === 'development' ? dev : prod;
export const API = process.env.NODE_ENV === 'development'
                        ? (
                            (document.location.hostname === '127.0.0.1' || document.location.hostname === 'localhost')
                              ? local : dev
                          ) : prod;
