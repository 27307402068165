import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Accordion, Card, Col, Container, Image, Link, Row} from 'react-bootstrap';
import Bread_crumb from '../../components/BreadCrumb/breadcrumbs';
import getData from '../../services.js';
import LoadingScreen from "../../components/loading/loading";

const breadcrumbs = ({
  "Pages":
    [
      {
        "title": "EIXOS",
        "link": "/eixos",
        "active": false
      },
      {
        "title": "MATRIZ",
        "link": "",
        "active": true
      }
    ]
});

const EixoApresentacao = ({ match }) => {
  const [dataEixos, setDataEixos] = useState([]);
  const [eixoSelected, setEixoSelected] = useState(undefined);
  const [dataMatrix, setDataMatrix] = useState([]);
  const [marco, setMarco] = useState([]);

  const history = useHistory();

  useEffect(() => {
      getData('axes/')
        .then((resposta) => {
          setDataEixos(resposta);
          setEixoSelected(resposta.find(x => x.id === match.params.id));
        })
        .catch((err) => {
          console.log(err.message);
        });
  }, []);

  useEffect(() => {
      getData('axes/id/'+ match.params.id + '/matrix/')
        .then((resp) => {

          setMarco(resp.pop());

          setDataMatrix(resp);
        })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

    useEffect(() => {
        if (eixoSelected !== undefined) {
            document.title = "OAPS - Matrizes do Eixo: " + eixoSelected.title;
        }
    }, [eixoSelected]);

  const handlerLink = (link) => {
    history.push('/eixos/' + match.params.id + link);
  }

  function container() {
      return (
              <Container>
                  <Row className='img-eixos-linha'>
                      <div className='div-eixos-linha'>
                          {dataEixos.map((bc, index) => (
                              <Col tooltip='eixo' xs={1} md={1} sm={1}>
                                  <a href={'/eixos/' + bc.id + '/apresentacao'}>
                                      <Image fluid alt={bc.titulo} className='img-eixo'
                                             src={(bc.id === match.params.id ? bc.color_image : bc.transparent_image)}
                                             roundedCircle/>
                                  </a>
                              </Col>
                          ))}
                      </div>
                  </Row>
                  <Row className='div-eixo-link mouse'>
                      <Col onClick={() => handlerLink('/apresentacao')}
                           style={{backgroundColor: `${eixoSelected.color_3}`, color: `${eixoSelected.color_1}`}} xs={3} md={3} sm={3}>
                          Apresentação do Eixo
                      </Col>
                      <Col onClick={() => handlerLink('/producoes')}
                           style={{backgroundColor: `${eixoSelected.color_3}`, color: `${eixoSelected.color_1}`}}>
                          Produções
                      </Col>
                      <Col className='color-font-eixo' style={{backgroundColor: `${eixoSelected.color_1}`}}
                           xs={3} md={3} sm={3}>
                          Matriz
                      </Col>
                      <Col onClick={() => handlerLink('/linha-do-tempo')}
                           style={{backgroundColor: `${eixoSelected.color_3}`, color: `${eixoSelected.color_1}`}} xs={3} md={3} sm={3}>
                          Linha do Tempo
                      </Col>
                  </Row>
                  <Row>
                  <Container className='fundoBranco' style={{minHeight: '20vw'}}>
                    {marco !== undefined &&
                      <Row className='pt-2 pl-4 marco-zero' style={{color:`${eixoSelected.color_1}`, textDecoration: 'underline'}}>
                        <a href={marco.link}>
                            {marco.matrix !== undefined && marco.matrix.map(m =>
                              <a href={m.file} target="_blank" rel="noopener noreferrer"
                                  style={{color:`${eixoSelected.color_1}`}}>
                              {m.topic}
                              </a>
                            )}
                        </a>
                      </Row>
                    }
                    <Row>
                    <Col  xs={12} md={12} sm={12}>
                      <Accordion className='mouse'>
                          {dataMatrix !== undefined && dataMatrix.map((ano, i) => (
                              <>
                                  <Accordion.Toggle style={{backgroundColor: `white`,
                                                            color:`${eixoSelected.color_1}`,
                                                            borderColor:`${eixoSelected.color_1}`}}
                                                    as={Card.Header} eventKey={'' + i}>
                                      {ano.year}
                                      {/*seta*/}
                                      {ano.matrix !== undefined &&
                                        ano.matrix.length > 0 && <svg fill={eixoSelected.color_1} width={32}
                                            height={32} xmlns="http://www.w3.org/2000/svg" x="26px" y="26px" viewBox="0 0 26 25">
                                      <polygon points="12.88,17.76 4.47,9.34 21.29,9.34 "/>
                                      </svg>}
                                  </Accordion.Toggle>
                                  <Accordion.Collapse eventKey={'' + i}>
                                      <Container style={{backgroundColor: 'white', textDecoration: 'underline' }}>
                                          <Row>
                                                  {ano.matrix.map((m,i) => (
                                                    <>
                                                    <Col className='pl-5' xs={12} md={12} sm={12}>
                                                    <a href={m.file} target="_blank" rel="noopener noreferrer"
                                                        style={{color:`${eixoSelected.color_1}`}}>
                                                    {i+1 + '. '}{m.topic}
                                                    </a>
                                                    </Col>
                                                    <br />
                                                    </>
                                                  ))}
                                          </Row>
                                      </Container>
                                  </Accordion.Collapse>
                              </>
                          ))}
                      </Accordion>
                      </Col>
                      </Row>
                  </Container>
                  </Row>
              </Container>
      )
  }

    return (
        <>
            <Bread_crumb pages={breadcrumbs}/>
            {dataEixos.length > 0 && eixoSelected !== undefined ?
                container()
                :
                <LoadingScreen/>
            }
        </>
    )
}

export default EixoApresentacao;
