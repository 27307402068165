export const validate = values => {
    const errors = {};
    if (!values.name) {
        errors.name = '* Nome é Obrigatório';
    } else if (values.name.length <= 4) {
        errors.name = 'Tamanho do nome insuficiente!';
    }

    if (!values.email) {
        errors.email = '* E-mail é Obrigatório';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'E-mail está inválido!';
    }

    return errors;
};