import React from 'react';
import {Col, Container, Image, Row} from 'react-bootstrap';
import "./eventsList.css"
import {dayMonth, yearMonth} from "../../ui/utils/date";
import defaultImageRetangular from "../../ui/imgs/default/retangular.jpg"
import {Link} from "react-router-dom";

const EventsList = ({list}) => {
    //Functions
    function timeLeft(date) {
        const splittedDate = date.split("/");

        const today = new Date();
        const year = today.getFullYear()

        if (year > splittedDate[2])
            return ""

        const day = today.getDate()
        const month = today.getMonth()

        let daysLeft = splittedDate[0] - (day + 1)
        let monthsLeft = splittedDate[1] - (month + 1)

        let partOne = monthsLeft > 1 ? "Faltam " + monthsLeft + " meses" : "Falta " + monthsLeft + " mês"
        let partTwo = daysLeft > 1 ? monthsLeft + " dias" : monthsLeft + " dia"

        if (monthsLeft > 0 && daysLeft > 0)
            return partOne + " e " + partTwo + " para o evento acontecer!"
        else if (monthsLeft === 0 && daysLeft > 1)
            return "Faltam " + daysLeft + " para o evento acontecer!"
        else if (monthsLeft === 0 && daysLeft === 1)
            return "O evento é amanhã. Confira!"
        else if (monthsLeft === 0 && daysLeft === 0)
            return "Hoje é o dia do evento. Participe!"
        else if (monthsLeft > 0)
            return partOne + " para o evento acontecer!"
        else return ""
    }

    function renderList() {
        return list.map((item) => (
                <Link key={item.id} to={'/evento/' + yearMonth(item.date) + '/' + item.id + '/'}>
                    <Row className="event_list-side-box-margin">
                        <Col style={{display: 'flex'}}>
                            <Col xs={0} sm={0} md={0} lg={3} xl={3}
                                 className="event_list-side-box">
                                <Container className="event_list-side-text">
                                    <h3 className="event_list-side-day">{"DIA " + dayMonth(item.date)}</h3>
                                    <br/>
                                    <h6>{item.title}</h6>
                                    <br/>
                                    <h6>{timeLeft(item.date)}</h6>
                                </Container>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={9} xl={9} style={{paddingLeft: '0px'}}>
                                <Image fluid src={item.image || defaultImageRetangular} style={{width: '200%'}}
                                       alt={item.image_legend}/>
                            </Col>
                        </Col>
                    </Row>
                </Link>
            )
        )
    }

    return (
        <>
            {renderList()}
        </>
    )
}

export default EventsList;
