import React, {useState} from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import {Link} from 'react-router-dom';

const NavBar = () => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar expanded={expanded} fixed="top" expand="lg" variant="dark">
            <Navbar.Brand as={Link} onClick={() => setExpanded(false)} to="/">OAPS</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"
                           onClick={() => setExpanded(expanded ? false : "expanded")}/>
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mx-auto navbar-color">
                    <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/quem-somos">
                        <div className="navbar-item">QUEM SOMOS</div>
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/eixos">
                        <div className="navbar-item">EIXOS</div>
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/matrizes">
                        <div className="navbar-item">MATRIZES</div>
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/debates">
                        <div className="navbar-item">DEBATES</div>
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/noticias">
                        <div className="navbar-item">NOTÍCIAS</div>
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/entrevistas">
                        <div className="navbar-item">ENTREVISTAS</div>
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/ta-na-midia">
                        <div className="navbar-item">TÁ NA MÍDIA</div>
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/boletins">
                        <div className="navbar-item">BOLETINS</div>
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/links">
                        <div className="navbar-item">LINKS</div>
                    </Nav.Link>
                    <Nav.Link as={Link} onClick={() => setExpanded(false)} to="/eventos">
                        <div className="navbar-item">EVENTOS</div>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavBar;
