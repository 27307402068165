import React, {useEffect} from 'react';
import {Col, Container, Image, Row, Spinner} from "react-bootstrap"
import logo from "../../ui/imgs/logoBola.png";
import PageBreadCrumb from "../../components/BreadCrumb/breadcrumbs";
import './cases.css'

const Development = () => {
    //Environment
    const breadcrumbs = ({
        "Pages": [{
            "title": "EM DESENVOLVIMENTO",
            "link": "/desenvolvimento",
            "active": true
        }]
    });

    //Effects
    useEffect(() => {
        document.title = "OAPS - Em Desenvolvimento";
    }, []);

    return (
        <>
            <PageBreadCrumb pages={breadcrumbs}/>
            <Container fluid className='cases-background-white-color cases-other-page'>
                <main className="center align-content-center">
                    <Row>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2}/>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <Image fluid src={logo} className="mx-auto" alt="Em desenvolvimento!"/>
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2}/>
                    </Row>
                    <Row className="cases-loading-text">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <b><h3>Em desenvolvimento!</h3></b>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Spinner animation="grow" size="md" className="d-flex spinner-center spinner-size-lg"/>
                        </Col>
                    </Row>
                </main>
            </Container>
        </>
    );
}

export default Development;