import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';
import getData from '../../services.js';
import GridYears from "../../components/gridAndList/gridYears";
import {useHistory} from "react-router-dom";
import BulletinsList from "./bulletinsList";
import LoadingScreen from "../../components/loading/loading";
import SubscriptionForm from "./subscriptionForm";
import Ops from "../cases/ops";

const Boletins = ({match}) => {
    //Data
    const [yearsList, setYearsList] = useState([]);
    const [currentYear, setCurrentYear] = useState(undefined);
    const [instanceList, setInstanceList] = useState([]);
    //Environment
    const history = useHistory();
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "BOLETINS",
            "link": "",
            "active": true
        }]
    });

    //Effects
    useEffect(function getYearsFromServer() {
        getData('bulletins/years/').then((resp) => {
            setYearsList(resp);
            let year = match.params.year === undefined ?
                resp[resp.length - 1] :
                match.params.year;
            setCurrentYear(year);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, [match.params.year]);

    useEffect(function getInstancesFromServer() {
        if (currentYear !== undefined) {
            getData('bulletins/years/' + currentYear + '/').then((resp) => {
                setInstanceList(resp);
            }).catch((err) => {
                console.log(err.message);
                setOps(1)
            });
        }
    }, [currentYear]);

    useEffect(function updateTitle() {
        if (currentYear !== undefined && history) {
            document.title = "OAPS - Boletins de " + currentYear;
            history.push('/boletins/' + currentYear + '/');
        }
    }, [currentYear, history]);

    //Functions
    function container() {
        return (
            <Container>
                <SubscriptionForm/>
                <GridYears yearsList={yearsList} currentYear={currentYear} link_to="/boletins/"/>
                <BulletinsList itemsList={instanceList} link_to={'/boletim/'} currentYear={currentYear}/>
            </Container>
        )
    }

    return (
        <>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && yearsList.length > 0 && instanceList.length > 0 ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    )
}

export default Boletins;
