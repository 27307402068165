import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';
import getData from '../../services.js';
import LoadingScreen from "../../components/loading/loading";
import Calendar from "./calendar";
import {useHistory} from "react-router-dom";
import EventsList from "./eventsList";
import Ops from "../cases/ops";

const Events = ({match}) => {
    //Data
    const [selected, setSelected] = useState(undefined);
    const [calendarData, setCalendarData] = useState([]);
    const [instanceList, setinstanceList] = useState([]);
    const [year, setYear] = useState(undefined);
    const [month, setMonth] = useState(undefined);
    //Environment
    const history = useHistory();
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "EVENTOS",
            "link": "",
            "active": true
        }]
    });

    useEffect(function getYearsInstancesFromServer() {
        if (selected !== undefined) {
            getData('events/' + selected + '/').then((resp) => {
                setinstanceList(resp);
            }).catch((err) => {
                console.log(err.message);
                setOps(1)
            });
        }
    }, [selected]);

    useEffect(function getYearsFromServer() {
        getData('events/months/')
            .then((resp) => {
                setCalendarData(resp);
                let toSelect;
                if (match.params.year === undefined || match.params.month === "undefined") {
                    let data = resp[0].split('/');
                    setYear(parseInt(data[0]))
                    setMonth(parseInt(data[1]))
                    toSelect = resp[0]
                } else {
                    setYear(parseInt(match.params.year))
                    setMonth(parseInt(match.params.month))
                    toSelect = match.params.year + "/" + match.params.month
                }
                // history.push('/eventos/' + toSelect + '/');
                setSelected(toSelect)
            }).catch((err) => {
                console.log(err.message);
                setOps(1)
            });
    }, [match.params.month, match.params.year]);

    useEffect(function updateTitle() {
        if (instanceList !== undefined && instanceList.length > 0 && selected !== undefined) {
            document.title = "OAPS - Eventos de " + selected;
            history.push('/eventos/' + selected + '/');
        }
    }, [instanceList, selected]);

    function container() {
        return (
            <Container fluid id={"events"}>
                <Calendar list={calendarData} link_to={"/eventos/"} functionality={"event"} year={year} month={month}/>
                <EventsList list={instanceList}/>
            </Container>
        )
    }

    return (
        <>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && calendarData.length > 0 && instanceList.length > 0 ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    )
}

export default Events;
