import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';
import getData from '../../services.js';
import DataInstance from "../../components/contentDataInstance/dataInstance";
import Comments from "../../components/comments/comments";
import GridYears from "../../components/gridAndList/gridYears";
import LoadingScreen from "../../components/loading/loading";
import Ops from "../cases/ops";

const Interview = ({match}) => {
    //Data
    const [yearsList, setYearsList] = useState([]);
    const [instance, setInstancia] = useState(undefined);
    //Environment
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "ENTREVISTAS",
            "link": "/entrevistas/",
            "active": false
        }, {
            "title": "ENTREVISTA",
            "link": "",
            "active": true
        }]
    });

    //Effects
    useEffect(function getYearsFromServer() {
        getData('interviews/years/').then((resp) => {
            setYearsList(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, []);

    useEffect(function getInstanceFromServer() {
        getData('interviews/id/' + match.params.id + '/').then((resp) => {
            setInstancia(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, [match.params.id]);

    useEffect(function updateTitle() {
        if (instance !== undefined) {
            document.title = "OAPS - " + instance.title;
        }
    }, [instance]);

    //Functions
    function container() {
        return (
            <Container fluid id={"interview_" + match.params.id}>
                <GridYears yearsList={yearsList} currentYear={match.params.year} link_to={"/entrevistas/"}/>
                <DataInstance instance={instance}
                              link_to={"/entrevista/" + match.params.year + "/" + match.params.id + "/"}
                              enconded_link={"%2Fentrevista%2F" + match.params.year + "%2F" + match.params.id + "%2F"}/>
                <br/>
                <Comments id={instance.id} functionality="interviews" comments={instance.comments}/>
            </Container>
        )
    }

    return (
        <>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && yearsList.length > 0 && instance !== undefined ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    );
}

export default Interview;
