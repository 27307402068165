import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Accordion, Card, Col, Container, Image, Link, Media, Row} from 'react-bootstrap';
import Bread_crumb from '../../components/BreadCrumb/breadcrumbs';
import getData from '../../services.js';
import LoadingScreen from "../../components/loading/loading";
import { formataData } from '../../ui/utils/date.js';

const breadcrumbs = ({
  "Pages":
    [
      {
        "title": "EIXOS",
        "link": "/eixos",
        "active": false
      },
      {
        "title": "APRESENTAÇÃO",
        "link": "",
        "active": true
      }
    ]
});
//TODO: Resolver a questão da pesquisa Geral
//TODO: Lista de eixos encima, colocar um hover com a imagem selecionada colorida
const EixoApresentacao = ({ match }) => {
  const [dataEixos, setDataEixos] = useState([]);
  const [dataApresentacao, setDataApresentacao] = useState(undefined);
  const [dataPesquisa, setDataPesquisa] = useState([]);
  const [currentActiveKey, setCurrentActiveKey] = useState(null);

  const history = useHistory();

  useEffect(() => {
      getData('axes/')
        .then((resposta) => {
          setDataEixos(resposta);
        })
        .catch((err) => {
          console.log(err.message);
        });
  }, []);

  useEffect(() => {
      getData('axes/id/'+ match.params.id + '/')
        .then((resp) => {
          setDataApresentacao(resp);
        })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

    useEffect(() => {
        if (dataApresentacao !== undefined) {
            document.title = "OAPS - Apresentação do Eixo: " + dataApresentacao.title;
        }
    }, [dataApresentacao]);

  const buscaPesquisa = (id) => {
      getData('axes/research/'+ id + '/')
        .then((respost) => {
          setDataPesquisa(respost);
        })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handlerLink = (link) => {
    history.push('/eixos/' + match.params.id + link);
  }
  //TODO: A mão do mouse esta aparecendo dentro da caixa do acorddeon. Colocar apenas sobre o header e o link.
  function container(){
      return (
        <Container>
            <Row className='img-eixos-linha'>
                <Col xs={12} md={12} sm={12} className='div-eixos-linha'>
                {dataEixos.map((bc,index) => (
                      <Col key={index} xs={1} md={1} sm={1}>
                      <a href={'/eixos/' + bc.id + '/apresentacao'}>
                        <Image fluid alt={bc.titulo} className='img-eixo'
                        src={(bc.id === match.params.id ? bc.color_image : bc.transparent_image)} roundedCircle />
                      </a>
                      </Col>
                ))}
                </Col>
            </Row>
            <Row className='div-eixo-link mouse'>
              <Col className='color-font-eixo'
                      style={{backgroundColor:`${dataApresentacao.color_1}`}}
                      >
                Apresentação do Eixo
              </Col>
              <Col xs={3} md={3} sm={3} onClick={() => handlerLink('/producoes')}
                      style={{backgroundColor:`${dataApresentacao.color_3}`, color: `${dataApresentacao.color_1}`}}>
                Produções
              </Col>
              <Col onClick={() => handlerLink('/matriz')}
                      style={{backgroundColor:`${dataApresentacao.color_3}`, color: `${dataApresentacao.color_1}`}}
                      xs={3} md={3} sm={3}>
                Matriz
              </Col>
              <Col onClick={() => handlerLink('/linha-do-tempo')}
                      style={{backgroundColor:`${dataApresentacao.color_3}`, color: `${dataApresentacao.color_1}`}}
                      xs={3} md={3} sm={3}>
                Linha do Tempo
              </Col>
            </Row>
            <Row>
                <Container className='fundoBranco px-5 py-3'
                          style={{color: `${dataApresentacao.color_1}`}}>
                  <Row className='bolder mouse'>
                    {dataApresentacao.title !== undefined && dataApresentacao.title.toUpperCase()}
                  </Row>
                  <Row>
                    Coordenação:
                  </Row>
                  {dataApresentacao.axis_coordinator !== undefined && dataApresentacao.axis_coordinator.map((bc,index) => (
                    <Row>
                      {bc.treatment + ' ' + bc.name + ' (' + bc.institution + ')' }
                    </Row>
                  ))}
                  <br />
                  <Row className='text-justify'><div dangerouslySetInnerHTML={{ __html: dataApresentacao.body }} /></Row>

                  <Row className='bolder'>ESTUDOS E PESQUISAS</Row>
                  <Row  className='mouse'>
                  <Accordion style={{width: '100%'}}>
                    {dataApresentacao.research !== undefined && dataApresentacao.research.map((pesq, i) => (
                      <>
                        <Accordion.Toggle as={Card.Header}
                            style={currentActiveKey === i ? {backgroundColor:`${dataApresentacao.color_1}`, color: `${dataApresentacao.color_3}`} : {backgroundColor:`${dataApresentacao.color_3}`, color: `${dataApresentacao.color_1}`}} eventKey={''+i}
                            onClick={() => {buscaPesquisa(pesq.id); setCurrentActiveKey(i);}}>
                          <b>{i+1 + '. ' + pesq.title}</b>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={''+i}>
                          <Container className='p-4' style={{backgroundColor:`${dataApresentacao.color_1}`, color:'white'}}>
                          <Row className='px-2 bolder'><Col>OBJETIVO GERAL:</Col></Row>
                          <Row className='px-2 text-justify'><div dangerouslySetInnerHTML={{ __html: dataPesquisa.body }} /></Row>
                          <Row className='px-2'><Col><b>Pesquisador(es): </b> {dataPesquisa.researchers}</Col></Row>
                          {dataPesquisa !== undefined
                                && dataPesquisa.scientific_work !== undefined
                                && dataPesquisa.scientific_work.length > 0 &&
                              <Container className='pt-4 pl-4'>
                                <Row className='bolder'>
                                  PRODUÇÕES CIENTÍFICAS DO EIXO:
                                </Row>
                                  <Row>
                                      <Container>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <ul className="list-unstyled small no-decorate">
                                                    {dataPesquisa.scientific_work.length > 0 && dataPesquisa.scientific_work.map(el => (
                                                        <a href={el.url} target="_blank" rel="noopener noreferrer">
                                                        <Media className='m-2 align-items-center' key={el.id} as="li">
                                                        {/*svg artigo*/}
                                                        {el.typology === 'Artigo' && <svg className='icone-producoes' width="50" height="64" viewBox="-1 -1 50 65" fill={dataApresentacao.color_3} xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd"
                                                              clipRule="evenodd"
                                                              d="M0 6C0 2.68629 2.68629 0 6 0H44C47.3137 0 50 2.68629 50 6V59C50 62.3137 47.3137 65 44 65H6C2.68629 65 0 62.3137 0 59V6ZM4 10C4 8.89543 4.89543 8 6 8H43C44.1046 8 45 8.89543 45 10C45 11.1046 44.1046 12 43 12H6C4.89543 12 4 11.1046 4 10ZM6 19C4.89543 19 4 19.8954 4 21C4 22.1046 4.89543 23 6 23H43C44.1046 23 45 22.1046 45 21C45 19.8954 44.1046 19 43 19H6ZM4 32C4 30.8954 4.89543 30 6 30H43C44.1046 30 45 30.8954 45 32C45 33.1046 44.1046 34 43 34H6C4.89543 34 4 33.1046 4 32ZM6 41C4.89543 41 4 41.8954 4 43C4 44.1046 4.89543 45 6 45H25C26.1046 45 27 44.1046 27 43C27 41.8954 26.1046 41 25 41H6ZM4 54C4 52.8954 4.89543 52 6 52H25C26.1046 52 27 52.8954 27 54C27 55.1046 26.1046 56 25 56H6C4.89543 56 4 55.1046 4 54ZM35.8433 48.92L37.5952 42.032C37.5952 42.016 37.6032 42.008 37.6193 42.008C37.6353 42.008 37.6432 42.016 37.6432 42.032L39.3952 48.92C39.4113 48.968 39.3953 49.016 39.3473 49.064C39.3153 49.096 39.2752 49.112 39.2272 49.112H36.0112C35.9632 49.112 35.9152 49.096 35.8672 49.064C35.8353 49.016 35.8273 48.968 35.8433 48.92ZM30.3713 55.592C30.5793 55.864 30.8512 56 31.1873 56H32.6992C33.0993 56 33.4593 55.88 33.7793 55.64C34.0993 55.384 34.3073 55.064 34.4033 54.68L34.9793 52.4C35.0112 52.272 35.0913 52.208 35.2192 52.208H40.0192C40.1632 52.208 40.2433 52.272 40.2593 52.4L40.8353 54.68C40.9313 55.064 41.1393 55.384 41.4593 55.64C41.7793 55.88 42.1393 56 42.5393 56H44.1473C44.4832 56 44.7472 55.864 44.9393 55.592C45.1473 55.32 45.1953 55.024 45.0833 54.704L40.2593 39.776C40.1473 39.392 39.9233 39.08 39.5872 38.84C39.2513 38.6 38.8753 38.48 38.4593 38.48H36.8753C36.4753 38.48 36.1073 38.6 35.7713 38.84C35.4513 39.08 35.2272 39.392 35.0993 39.776L30.2512 54.704C30.1392 55.024 30.1793 55.32 30.3713 55.592Z"
                                                              />
                                                        </svg>}
                                                        {/*svg documento*/}
                                                        {el.typology === 'Documento' && <svg width="50" height="64" viewBox="-1 -1 50 65" fill={dataApresentacao.color_3} xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6 0C2.68629 0 0 2.68629 0 6V59C0 62.3137 2.68629 65 6 65H44C47.3137 65 50 62.3137 50 59V6C50 2.68629 47.3137 0 44 0H6ZM6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12H43C44.1046 12 45 11.1046 45 10C45 8.89543 44.1046 8 43 8H6ZM4 21C4 19.8954 4.89543 19 6 19H43C44.1046 19 45 19.8954 45 21C45 22.1046 44.1046 23 43 23H6C4.89543 23 4 22.1046 4 21ZM6 30C4.89543 30 4 30.8954 4 32C4 33.1046 4.89543 34 6 34H43C44.1046 34 45 33.1046 45 32C45 30.8954 44.1046 30 43 30H6ZM4 43C4 41.8954 4.89543 41 6 41H25C26.1046 41 27 41.8954 27 43C27 44.1046 26.1046 45 25 45H6C4.89543 45 4 44.1046 4 43ZM6 52C4.89543 52 4 52.8954 4 54C4 55.1046 4.89543 56 6 56H25C26.1046 56 27 55.1046 27 54C27 52.8954 26.1046 52 25 52H6Z"
                                                        />
                                                        </svg>}
                                                        {/*svg Disserações*/}
                                                        {el.typology === 'Mestrado' && <svg width="50" height="64" viewBox="-1 -1 50 65" fill={dataApresentacao.color_3} xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 6C0 2.68629 2.68629 0 6 0H44C47.3137 0 50 2.68629 50 6V59C50 62.3137 47.3137 65 44 65H6C2.68629 65 0 62.3137 0 59V6ZM4 10C4 8.89543 4.89543 8 6 8H43C44.1046 8 45 8.89543 45 10C45 11.1046 44.1046 12 43 12H6C4.89543 12 4 11.1046 4 10ZM6 19C4.89543 19 4 19.8954 4 21C4 22.1046 4.89543 23 6 23H43C44.1046 23 45 22.1046 45 21C45 19.8954 44.1046 19 43 19H6ZM4 32C4 30.8954 4.89543 30 6 30H43C44.1046 30 45 30.8954 45 32C45 33.1046 44.1046 34 43 34H6C4.89543 34 4 33.1046 4 32ZM6 41C4.89543 41 4 41.8954 4 43C4 44.1046 4.89543 45 6 45H25C26.1046 45 27 44.1046 27 43C27 41.8954 26.1046 41 25 41H6ZM4 54C4 52.8954 4.89543 52 6 52H25C26.1046 52 27 52.8954 27 54C27 55.1046 26.1046 56 25 56H6C4.89543 56 4 55.1046 4 54ZM35.0228 52.688V41.792C35.0228 41.648 35.0948 41.56 35.2388 41.528C35.5588 41.464 35.9428 41.432 36.3908 41.432C37.9908 41.432 39.1828 41.88 39.9668 42.776C40.7508 43.672 41.1428 45.08 41.1428 47C41.1428 49.144 40.7508 50.688 39.9668 51.632C39.1988 52.576 38.0068 53.048 36.3908 53.048C35.9428 53.048 35.5588 53.016 35.2388 52.952C35.0948 52.92 35.0228 52.832 35.0228 52.688ZM31.4228 55.568C31.6788 55.856 31.9988 56.016 32.3828 56.048C33.6308 56.176 34.8628 56.24 36.0788 56.24C38.9428 56.24 41.1508 55.464 42.7028 53.912C44.2548 52.344 45.0308 50.04 45.0308 47C45.0308 44.2 44.2548 42.04 42.7028 40.52C41.1508 39 38.9428 38.24 36.0788 38.24C34.8628 38.24 33.6308 38.304 32.3828 38.432C31.9988 38.464 31.6788 38.632 31.4228 38.936C31.1668 39.224 31.0388 39.568 31.0388 39.968V54.512C31.0388 54.912 31.1668 55.264 31.4228 55.568Z"/>
                                                        </svg>}
                                                        {/*svg Tese*/}
                                                        {el.typology === 'Doutorado' && <svg width="50" height="64" viewBox="-1 -1 50 65" fill={dataApresentacao.color_3} xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 6C0 2.68629 2.68629 0 6 0H44C47.3137 0 50 2.68629 50 6V59C50 62.3137 47.3137 65 44 65H6C2.68629 65 0 62.3137 0 59V6ZM4 10C4 8.89543 4.89543 8 6 8H43C44.1046 8 45 8.89543 45 10C45 11.1046 44.1046 12 43 12H6C4.89543 12 4 11.1046 4 10ZM6 19C4.89543 19 4 19.8954 4 21C4 22.1046 4.89543 23 6 23H43C44.1046 23 45 22.1046 45 21C45 19.8954 44.1046 19 43 19H6ZM4 32C4 30.8954 4.89543 30 6 30H43C44.1046 30 45 30.8954 45 32C45 33.1046 44.1046 34 43 34H6C4.89543 34 4 33.1046 4 32ZM6 41C4.89543 41 4 41.8954 4 43C4 44.1046 4.89543 45 6 45H25C26.1046 45 27 44.1046 27 43C27 41.8954 26.1046 41 25 41H6ZM4 54C4 52.8954 4.89543 52 6 52H25C26.1046 52 27 52.8954 27 54C27 55.1046 26.1046 56 25 56H6C4.89543 56 4 55.1046 4 54ZM31.1867 41.456C31.4587 41.728 31.7787 41.864 32.1467 41.864H35.2187C35.3467 41.864 35.4107 41.928 35.4107 42.056V54.632C35.4107 55 35.5467 55.32 35.8187 55.592C36.0907 55.864 36.4107 56 36.7787 56H38.2187C38.5867 56 38.9067 55.864 39.1787 55.592C39.4507 55.32 39.5867 55 39.5867 54.632V42.056C39.5867 41.928 39.6507 41.864 39.7787 41.864H42.8507C43.2187 41.864 43.5387 41.728 43.8107 41.456C44.0827 41.184 44.2187 40.864 44.2187 40.496V39.848C44.2187 39.48 44.0827 39.16 43.8107 38.888C43.5387 38.616 43.2187 38.48 42.8507 38.48H32.1467C31.7787 38.48 31.4587 38.616 31.1867 38.888C30.9147 39.16 30.7787 39.48 30.7787 39.848V40.496C30.7787 40.864 30.9147 41.184 31.1867 41.456Z"/>
                                                        </svg>}
                                                        {/*svg Video*/}
                                                        {el.typology === 'Video' && <svg width="83" height="50" viewBox="-1 -1 83 50" fill={dataApresentacao.color_3} xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M6 50C2.68629 50 2.06815e-06 47.3137 1.9233e-06 44L0 6C0 2.68629 2.68629 2.28671e-06 6 2.14186e-06L55 0C58.3137 0 61 2.68629 61 6V12.3756L78.25 2.41636C80.25 1.26166 82.75 2.70503 82.75 5.01443V43.9856C82.75 46.295 80.25 47.7384 78.25 46.5837L61 36.6244V44C61 47.3137 58.3137 50 55 50H6ZM37.5 27.0981C39.5 25.9434 39.5 23.0566 37.5 21.9019L21.75 12.8087C19.75 11.654 17.25 13.0973 17.25 15.4067L17.25 33.5933C17.25 35.9027 19.75 37.346 21.75 36.1913L37.5 27.0981Z"/>
                                                        </svg>}
                                                            <br/>
                                                            <Media.Body className='m-1'>
                                                              <Col xs={12} sm={12} md={12}>
                                                                <b>{el.title}</b>
                                                              </Col>
                                                              <Col xs={12} sm={12} md={12}>
                                                                <b>Pesquisador(es): </b>
                                                                    {el.researchers}
                                                              </Col>
                                                              <Col xs={12} sm={12} md={12}>
                                                                <b>Publicado em: </b>{el.year}
                                                              </Col>
                                                            </Media.Body>
                                                        </Media>
                                                        </a>
                                                    ))}
                                                </ul>
                                            </Col>
                                        </Row>
                                      </Container>
                                  </Row>
                              </Container>
                            }
                            {dataPesquisa !== undefined
                              && dataPesquisa.technical_work !== undefined
                              && dataPesquisa.technical_work.length > 0
                              &&  <Container className='py-4  pl-4'>
                              <Row className='bolder'>
                                  PRODUÇÕES TÉCNICAS DO EIXO:
                                </Row>
                              <Row>
                                  <Container>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <ul className="m-1 list-unstyled small no-decorate producoes">
                                                {dataPesquisa.technical_work.length > 0 && dataPesquisa.technical_work.map(el => (
                                                    <a href={el.url} target="_blank" rel="noopener noreferrer">
                                                    <Media className='m-2 align-items-center' key={el.id} as="li">
                                                    {/*svg artigo*/}
                                                    {el.typology === 'Artigo' && <svg width="50" height="64" viewBox="-1 -1 50 65" fill={dataApresentacao.color_3} xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd"
                                                          clipRule="evenodd"
                                                          d="M0 6C0 2.68629 2.68629 0 6 0H44C47.3137 0 50 2.68629 50 6V59C50 62.3137 47.3137 65 44 65H6C2.68629 65 0 62.3137 0 59V6ZM4 10C4 8.89543 4.89543 8 6 8H43C44.1046 8 45 8.89543 45 10C45 11.1046 44.1046 12 43 12H6C4.89543 12 4 11.1046 4 10ZM6 19C4.89543 19 4 19.8954 4 21C4 22.1046 4.89543 23 6 23H43C44.1046 23 45 22.1046 45 21C45 19.8954 44.1046 19 43 19H6ZM4 32C4 30.8954 4.89543 30 6 30H43C44.1046 30 45 30.8954 45 32C45 33.1046 44.1046 34 43 34H6C4.89543 34 4 33.1046 4 32ZM6 41C4.89543 41 4 41.8954 4 43C4 44.1046 4.89543 45 6 45H25C26.1046 45 27 44.1046 27 43C27 41.8954 26.1046 41 25 41H6ZM4 54C4 52.8954 4.89543 52 6 52H25C26.1046 52 27 52.8954 27 54C27 55.1046 26.1046 56 25 56H6C4.89543 56 4 55.1046 4 54ZM35.8433 48.92L37.5952 42.032C37.5952 42.016 37.6032 42.008 37.6193 42.008C37.6353 42.008 37.6432 42.016 37.6432 42.032L39.3952 48.92C39.4113 48.968 39.3953 49.016 39.3473 49.064C39.3153 49.096 39.2752 49.112 39.2272 49.112H36.0112C35.9632 49.112 35.9152 49.096 35.8672 49.064C35.8353 49.016 35.8273 48.968 35.8433 48.92ZM30.3713 55.592C30.5793 55.864 30.8512 56 31.1873 56H32.6992C33.0993 56 33.4593 55.88 33.7793 55.64C34.0993 55.384 34.3073 55.064 34.4033 54.68L34.9793 52.4C35.0112 52.272 35.0913 52.208 35.2192 52.208H40.0192C40.1632 52.208 40.2433 52.272 40.2593 52.4L40.8353 54.68C40.9313 55.064 41.1393 55.384 41.4593 55.64C41.7793 55.88 42.1393 56 42.5393 56H44.1473C44.4832 56 44.7472 55.864 44.9393 55.592C45.1473 55.32 45.1953 55.024 45.0833 54.704L40.2593 39.776C40.1473 39.392 39.9233 39.08 39.5872 38.84C39.2513 38.6 38.8753 38.48 38.4593 38.48H36.8753C36.4753 38.48 36.1073 38.6 35.7713 38.84C35.4513 39.08 35.2272 39.392 35.0993 39.776L30.2512 54.704C30.1392 55.024 30.1793 55.32 30.3713 55.592Z"
                                                          />
                                                    </svg>}
                                                    {/*svg documento*/}
                                                    {el.typology === 'Documento' && <svg width="50" height="65" viewBox="-1 -1 50 65" fill={dataApresentacao.color_3} xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 0C2.68629 0 0 2.68629 0 6V59C0 62.3137 2.68629 65 6 65H44C47.3137 65 50 62.3137 50 59V6C50 2.68629 47.3137 0 44 0H6ZM6 8C4.89543 8 4 8.89543 4 10C4 11.1046 4.89543 12 6 12H43C44.1046 12 45 11.1046 45 10C45 8.89543 44.1046 8 43 8H6ZM4 21C4 19.8954 4.89543 19 6 19H43C44.1046 19 45 19.8954 45 21C45 22.1046 44.1046 23 43 23H6C4.89543 23 4 22.1046 4 21ZM6 30C4.89543 30 4 30.8954 4 32C4 33.1046 4.89543 34 6 34H43C44.1046 34 45 33.1046 45 32C45 30.8954 44.1046 30 43 30H6ZM4 43C4 41.8954 4.89543 41 6 41H25C26.1046 41 27 41.8954 27 43C27 44.1046 26.1046 45 25 45H6C4.89543 45 4 44.1046 4 43ZM6 52C4.89543 52 4 52.8954 4 54C4 55.1046 4.89543 56 6 56H25C26.1046 56 27 55.1046 27 54C27 52.8954 26.1046 52 25 52H6Z"
                                                    />
                                                    </svg>}
                                                    {/*svg Disserações*/}
                                                    {el.typology === 'Mestrado' && <svg width="50" height="64" viewBox="-1 -1 50 65" fill={dataApresentacao.color_3} xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M0 6C0 2.68629 2.68629 0 6 0H44C47.3137 0 50 2.68629 50 6V59C50 62.3137 47.3137 65 44 65H6C2.68629 65 0 62.3137 0 59V6ZM4 10C4 8.89543 4.89543 8 6 8H43C44.1046 8 45 8.89543 45 10C45 11.1046 44.1046 12 43 12H6C4.89543 12 4 11.1046 4 10ZM6 19C4.89543 19 4 19.8954 4 21C4 22.1046 4.89543 23 6 23H43C44.1046 23 45 22.1046 45 21C45 19.8954 44.1046 19 43 19H6ZM4 32C4 30.8954 4.89543 30 6 30H43C44.1046 30 45 30.8954 45 32C45 33.1046 44.1046 34 43 34H6C4.89543 34 4 33.1046 4 32ZM6 41C4.89543 41 4 41.8954 4 43C4 44.1046 4.89543 45 6 45H25C26.1046 45 27 44.1046 27 43C27 41.8954 26.1046 41 25 41H6ZM4 54C4 52.8954 4.89543 52 6 52H25C26.1046 52 27 52.8954 27 54C27 55.1046 26.1046 56 25 56H6C4.89543 56 4 55.1046 4 54ZM35.0228 52.688V41.792C35.0228 41.648 35.0948 41.56 35.2388 41.528C35.5588 41.464 35.9428 41.432 36.3908 41.432C37.9908 41.432 39.1828 41.88 39.9668 42.776C40.7508 43.672 41.1428 45.08 41.1428 47C41.1428 49.144 40.7508 50.688 39.9668 51.632C39.1988 52.576 38.0068 53.048 36.3908 53.048C35.9428 53.048 35.5588 53.016 35.2388 52.952C35.0948 52.92 35.0228 52.832 35.0228 52.688ZM31.4228 55.568C31.6788 55.856 31.9988 56.016 32.3828 56.048C33.6308 56.176 34.8628 56.24 36.0788 56.24C38.9428 56.24 41.1508 55.464 42.7028 53.912C44.2548 52.344 45.0308 50.04 45.0308 47C45.0308 44.2 44.2548 42.04 42.7028 40.52C41.1508 39 38.9428 38.24 36.0788 38.24C34.8628 38.24 33.6308 38.304 32.3828 38.432C31.9988 38.464 31.6788 38.632 31.4228 38.936C31.1668 39.224 31.0388 39.568 31.0388 39.968V54.512C31.0388 54.912 31.1668 55.264 31.4228 55.568Z"/>
                                                    </svg>}
                                                    {/*svg Tese*/}
                                                    {el.typology === 'Doutorado' && <svg width="50" height="64" viewBox="-1 -1 50 65" fill={dataApresentacao.color_3} xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M0 6C0 2.68629 2.68629 0 6 0H44C47.3137 0 50 2.68629 50 6V59C50 62.3137 47.3137 65 44 65H6C2.68629 65 0 62.3137 0 59V6ZM4 10C4 8.89543 4.89543 8 6 8H43C44.1046 8 45 8.89543 45 10C45 11.1046 44.1046 12 43 12H6C4.89543 12 4 11.1046 4 10ZM6 19C4.89543 19 4 19.8954 4 21C4 22.1046 4.89543 23 6 23H43C44.1046 23 45 22.1046 45 21C45 19.8954 44.1046 19 43 19H6ZM4 32C4 30.8954 4.89543 30 6 30H43C44.1046 30 45 30.8954 45 32C45 33.1046 44.1046 34 43 34H6C4.89543 34 4 33.1046 4 32ZM6 41C4.89543 41 4 41.8954 4 43C4 44.1046 4.89543 45 6 45H25C26.1046 45 27 44.1046 27 43C27 41.8954 26.1046 41 25 41H6ZM4 54C4 52.8954 4.89543 52 6 52H25C26.1046 52 27 52.8954 27 54C27 55.1046 26.1046 56 25 56H6C4.89543 56 4 55.1046 4 54ZM31.1867 41.456C31.4587 41.728 31.7787 41.864 32.1467 41.864H35.2187C35.3467 41.864 35.4107 41.928 35.4107 42.056V54.632C35.4107 55 35.5467 55.32 35.8187 55.592C36.0907 55.864 36.4107 56 36.7787 56H38.2187C38.5867 56 38.9067 55.864 39.1787 55.592C39.4507 55.32 39.5867 55 39.5867 54.632V42.056C39.5867 41.928 39.6507 41.864 39.7787 41.864H42.8507C43.2187 41.864 43.5387 41.728 43.8107 41.456C44.0827 41.184 44.2187 40.864 44.2187 40.496V39.848C44.2187 39.48 44.0827 39.16 43.8107 38.888C43.5387 38.616 43.2187 38.48 42.8507 38.48H32.1467C31.7787 38.48 31.4587 38.616 31.1867 38.888C30.9147 39.16 30.7787 39.48 30.7787 39.848V40.496C30.7787 40.864 30.9147 41.184 31.1867 41.456Z"/>
                                                    </svg>}
                                                    {/*svg Video*/}
                                                    {el.typology === 'Video' && <svg className='icone-producoes' width="83" height="50" viewBox="-1 -1 83 50" fill={dataApresentacao.color_3} xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M6 50C2.68629 50 2.06815e-06 47.3137 1.9233e-06 44L0 6C0 2.68629 2.68629 2.28671e-06 6 2.14186e-06L55 0C58.3137 0 61 2.68629 61 6V12.3756L78.25 2.41636C80.25 1.26166 82.75 2.70503 82.75 5.01443V43.9856C82.75 46.295 80.25 47.7384 78.25 46.5837L61 36.6244V44C61 47.3137 58.3137 50 55 50H6ZM37.5 27.0981C39.5 25.9434 39.5 23.0566 37.5 21.9019L21.75 12.8087C19.75 11.654 17.25 13.0973 17.25 15.4067L17.25 33.5933C17.25 35.9027 19.75 37.346 21.75 36.1913L37.5 27.0981Z"/>
                                                    </svg>}
                                                    <br/>
                                                        <Media.Body className='m-1'>
                                                          <Col xs={12} sm={12} md={12}>
                                                            <b>{el.title}</b>
                                                          </Col>
                                                          <Col xs={12} sm={12} md={12}>
                                                            <b>Pesquisador(es): </b>
                                                                {el.researchers}
                                                          </Col>
                                                          <Col xs={12} sm={12} md={12}>
                                                            <b>Postado em: </b>{formataData(el.created_at)}
                                                          </Col>
                                                        </Media.Body>
                                                    </Media>
                                                    </a>
                                                ))}
                                            </ul>
                                        </Col>
                                    </Row>
                                  </Container>
                              </Row>
                              </Container>
                            }
                          </Container>
                        </Accordion.Collapse>
                      </>
                    ))}
                  </Accordion>
                  </Row>
                </Container>
                </Row>
          </Container>
      )
  }
  return (
        <>
            <Bread_crumb pages={breadcrumbs}/>
            {dataEixos.length > 0 && dataApresentacao !== undefined?
                container()
                :
                <LoadingScreen/>
            }
        </>
    )
}



export default EixoApresentacao;
