import React from 'react';
import {Col, Container, Image, Row} from 'react-bootstrap';
import {formataData} from "../../ui/utils/date";
import Files from "./fileList";
import SocialMediaShare from "./socialMediaShare";
import './dataInstance.css'
import defaultImageRetangular from "../../ui/imgs/default/retangular.jpg";

const DataInstance = ({instance, link_to, enconded_link}) => {
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container className='data_instance-background-color'>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <figure className='data_instance-figure-box'>
                                <Image fluid src={instance.image || defaultImageRetangular} style={{width: '200%'}}
                                       alt={instance.alt_image || instance.title || "Sem legenda"}
                                />
                            </figure>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <main className='data_instance-principal-text-box'>
                                <section>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <h4 className='data_instance-principal-text data_instance-blue-text'>
                                            {instance.title}
                                        </h4>
                                    </Col>
                                </section>
                                <section>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <h5 className='data_instance-blue-text'>
                                            Autor: {instance.source_of_information}
                                        </h5>
                                    </Col>
                                </section>
                                <section>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <h6 className='data_instance-blue-text'>
                                            Publicado em: {formataData(instance.created_at)}
                                        </h6>
                                    </Col>
                                </section>
                                <section>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <h6 className='data_instance-blue-text'>
                                            Atualizado em: {formataData(instance.updated_at)}
                                        </h6>
                                    </Col>
                                </section>
                                <section>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <div className='data_instance-body' dangerouslySetInnerHTML={{__html: instance.body}}/>
                                    </Col>
                                </section>
                                <Files fileList={instance.files}/>
                                <br/>
                                <SocialMediaShare link_to={link_to} enconded_link={enconded_link} title={instance.title}/>
                                <br/>
                                <br/>
                            </main>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Row>
    );
}

export default DataInstance;
