import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';
import getData from '../../services.js';
import LoadingScreen from "../../components/loading/loading";
import Calendar from "./calendar";
import DataInstance from "../../components/contentDataInstance/dataInstance";
import {useHistory} from "react-router-dom";
import {getYearMonthByline} from "../../ui/utils/date";
import Ops from "../cases/ops";

const Event = ({match}) => {
    //Data
    const [instance, setInstance] = useState(undefined);
    const [calendarData, setCalendarData] = useState([]);
    //Environment
    const history = useHistory();
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "EVENTOS",
            "link": "/eventos/",
            "active": false
        }, {
            "title": "EVENTO",
            "link": "",
            "active": true
        }]
    });

    //Effects
    useEffect(function getInstanceFromServer() {
        getData('events/id/' + match.params.id + '/').then((resp) => {
            setInstance(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, [match.params.id]);

    useEffect(function getMonthsInstancesFromServer() {
        getData('events/months/').then((resp) => {
            setCalendarData(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, []);

    useEffect(function updateTitle() {
        if (instance !== undefined) {
            document.title = "OAPS - Evento: " + instance.title;
            history.push('/evento/' + getYearMonthByline(instance.date) + '/' + instance.id);
        }
    }, [instance]);

    //Functions
    function container() {
        return (
            <Container fluid id={"event_" + match.params.id}>
                <Calendar list={calendarData} link_to={"/eventos/"} functionality={"event"}
                          year={parseInt(match.params.year)} month={parseInt(match.params.month)}/>
                <DataInstance instance={instance}
                              link_to={"/evento/" + match.params.year + "/"  + match.params.month + "/"+ match.params.id + "/"}
                              enconded_link={"%2Fevento%2F" + match.params.year + "%2F" + match.params.month + "%2F" + match.params.id + "%2F"} />
            </Container>
        )
    }

    return (
        <>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && calendarData.length > 0 && instance !== undefined ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    )
}

export default Event;
