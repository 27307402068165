import React, {useState} from 'react';
import {Button, Col, Container, Form, Row, Spinner} from 'react-bootstrap';
import {useFormik} from 'formik';

import {sendComments} from '../../services';

import './commentForm.css'

const validate = values => {
    const errors = {};
    if (!values.author) {
        errors.author = '* Nome é Obrigatório';
    } else if (values.author.length <= 4) {
        errors.author = 'Tamanho do nome insuficiente!';
    }

    if (!values.email) {
        errors.email = '* E-mail é Obrigatório';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'E-mail está inválido!';
    }

    if (!values.body) {
        errors.body = '* Mensagem é Obrigatória';
    } else if (values.body.length <= 10) {
        errors.body = 'Tamanho da mensagem insuficiente!';
    }

    return errors;
};

const CommentForm = ({functionality, belongs_to}) => {
    const [check, setCheck] = useState(false);
    const [load, setLoad] = useState(false);

    const formik = useFormik({
        initialValues: {
            belongs_to: belongs_to,
            author: '',
            email: '',
            body: '',
        },
        validate,
        onSubmit: value => {
            console.log(value)
            setLoad(true);
            sendComments(functionality, value).then(async result => {
                let a = await result.json();
                setLoad(false);
                alert(a.message);

                if (result.ok && result.status !== 203) {
                    formik.resetForm();
                }

                return a;
            });
        },
    });

    /*  Deixando aqui só até os testes para ver as msgs que retornam

    function onSubmit(e) {
    const data = {"belongs_to": belongs_to, "author": dataNome, "email": dataEmail, "body": dataComentario}
    sendComments(functionality, data).then(resp => {
    alert("Comentário enviado com sucesso. Obrigado por participar!");
    }).catch((err) => {
    // console.log(err.message);
    console.log(err);
    alert("Algum erro aconteceu. Por favor tente mais tarde.");
    });
    }*/

    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container fluid>
                    <section>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <h4 className='texto-azul'>Deixe um comentário</h4>
                        </Col>
                    </section>
                    <section>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <h6>Antes de postar, por favor, leia nossos termos de uso.</h6>
                        </Col>
                    </section>
                    <br/>
                    <section>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Form hidden={load} onSubmit={formik.handleSubmit}>
                                <Form.Group controlId="commentForm.ControlInput1">
                                    <Form.Control type="text" name="author" onChange={formik.handleChange}
                                                  value={formik.values.author} placeholder="Nome:"
                                    />
                                    {formik.errors.author ?
                                        <label className='pl-4'>{formik.errors.author}</label> : null}
                                </Form.Group>
                                <Form.Group controlId="commentForm.ControlInput2">
                                    <Form.Control name="email" type="email" onChange={formik.handleChange}
                                                  value={formik.values.email} placeholder="E-mail:"
                                    />
                                    {formik.errors.email ?
                                        <label className='pl-4'>{formik.errors.email}</label> : null}
                                </Form.Group>
                                <Form.Group controlId="commentForm.ControlTextarea1">
                                    <Form.Control as="textarea" rows="3" name="body" placeholder="Comentário:"
                                                  onChange={formik.handleChange} value={formik.values.body}
                                    />
                                    {formik.errors.body ? <label className='pl-4'>{formik.errors.body}</label> : null}
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox">
                                    <i className='termo-uso'>
                                        <Form.Check type="checkbox" onChange={() => setCheck(!check)}
                                                    label="Li e concordo com os termos de uso."
                                        />
                                    </i>
                                </Form.Group>
                                <br/>
                            </Form>
                            <Spinner hidden={!load} animation="grow" size="md"
                                     className="spinner-center ta-na-midia-margin"/>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Col xs={{span: 4, offset: 8}} md={{span: 2, offset: 10}} sm={{span: 2, offset: 10}}>
                                <Button disabled={!check} className='w-100'
                                        onClick={formik.handleSubmit}>Enviar</Button>
                            </Col>
                        </Col>
                    </section>
                </Container>
            </Col>
        </Row>
    )
}

export default CommentForm;
