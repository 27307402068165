import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Col, Container, Figure, Row} from 'react-bootstrap';
import Bread_crumb from '../../components/BreadCrumb/breadcrumbs';

import getData from '../../services.js';
import LoadingScreen from "../../components/loading/loading";
import './axis.css'

const breadcrumbs = ({
    "Pages": [{
        "title": "EIXOS",
        "link": "/eixos",
        "active": true
    }]
});

const Axis = () => {
    const [dataEixos, setDataEixos] = useState([]);

    const history = useHistory();

    useEffect(() => {
        getData('axes/')
            .then((resp) => {
                setDataEixos(resp);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    useEffect(() => {
        document.title = "OAPS - Eixos";
    }, []);

    const handlerEixo = (eixoSelected) => {
        history.push('/eixos/' + eixoSelected + '/apresentacao');
    }
    //TODO: Circulo do hover esta aparecendo fora da imagem
    //TODO: O hover da legenda não esta aparecendo no celular

    function printAxis(bc) {
        return (
            <Col key={bc.id} xs={12} sm={6} md={6} lg={3} xl={3}>
                <Figure className='col-auto'>
                    <div onClick={() => handlerEixo(bc.id)} className='axis_list-legend'>
                        <div style={{
                            width: '100%',
                            height: '50%'
                        }}
                             className='col-auto axis_list-off'>
                            <div style={{
                                borderRadius: '50%',
                                width: '98%',
                                height: '100%',
                                padding: '41% 10% 0 10% ',
                                }}
                                 className='col-auto generic_mousePointer axis_list-img-legend'>
                                <span>{bc.title}</span>
                            </div>
                            <Figure.Image fluid alt={bc.title} className='axis-img' src={bc.color_image}/>
                        </div>
                    </div>
                </Figure>
            </Col>
        )
    }

    function container() {
        return (
            <Container className='axis_list'>
                <Row>
                    {dataEixos.map((bc, index) => (
                        printAxis(bc)
                    ))}
                </Row>
            </Container>
        )
    }

    return (
        <>
            <Bread_crumb pages={breadcrumbs}/>
            {dataEixos.length > 0 ?
                container()
                :
                <LoadingScreen/>
            }
        </>
    );
}
export default Axis;
