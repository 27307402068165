export function formataData(date) {
    if (date === undefined)
        return '';
    return new Date(date).toLocaleDateString('en-GB');
}

export function yearMonth(date) {
    let splittedDate = date.split("/");
    return splittedDate[2] + "/" + splittedDate[1]
}

export function dayMonth(date) {
    let splittedDate = date.split("/");
    return splittedDate[0] + "/" + splittedDate[1]
}

export function getYearMonthByline(date) {
    let splittedDate = date.split("-");
    return splittedDate[0] + "/" + splittedDate[1]
}

export function getMonthNameAndDay(dt) {
    let dd = dt.split('/');
    let array_mes = ["0", "JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];
    return array_mes[Number(dd[0])] + '/' + dd[1]
}
