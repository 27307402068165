import React from 'react';
import {Row} from 'react-bootstrap';
import './socialMediaShare.css'

const SocialMediaShare = ({link_to, enconded_link, title}) => {
    /*TODO: Melhorar o compartilhamento*/
    return (
        <Row>
            <div className="fb-share-button fb-share-button-custom"
                 data-href={"https://observatorio.analisepoliticaemsaude.org" + link_to}
                 data-layout="button" data-size="small">
                <a target="_blank"
                   href={"https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fobservatorio.analisepoliticaemsaude.org" + enconded_link + "&amp;src=sdkpreparse"}
                   className="fb-xfbml-parse-ignore">
                    Compartilhar
                </a>
            </div>
            <a href="https://twitter.com/share"
               className="twitter-share-button tw-share-button-custom"
               data-url={"https://observatorio.analisepoliticaemsaude.org" + link_to}
               data-text={"Leia: " + title} data-count="horizontal"
               data-via="Oaps" data-lang="pt">
               Tweetar
            </a>
        </Row>
    );
}

export default SocialMediaShare;
