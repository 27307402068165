import React from 'react';
import ReactDOM from 'react-dom';
import {Container} from 'react-bootstrap';
import { HelmetProvider } from "react-helmet-async";
import './index.css';

import {BrowserRouter as Router} from 'react-router-dom';

import NavBar from './ui/navBar';
import Topo from './ui/topo';
import Rodape from './ui/rodape';

import Page from './routes/routes.js';

ReactDOM.render(
    <Router>
    <HelmetProvider>
        <Container>
            <NavBar/>
            <Topo/>
            <div className='page'>
                <Page/>
            </div>
            <Rodape/>
        </Container>
        </HelmetProvider>
    </Router>
    ,
    document.getElementById('root')
);
