import {API} from './Constants';

const getData = async (arg) => {
    return fetch(API.url + arg).then(async (res) => {
        if (res.ok) {
            return await res.json();
        }
        throw new Error('Não foi possível pegar ' + arg + ' :(');
    });
}

export const sendMatriz = async (data) => {
    let rr = await sendGenerics('axes/matrix/download/', data);

    return rr;
}

export const sendContatoMessage = async (data) => {
    return await sendGenerics('contact/', data);
}

export function sendComments(functionality, data) {
    return sendGenerics(functionality + '/comments/', data);
}

export const cadastroBoletim = async (data) => {
    return await sendGenerics('bulletins/subscribe/', data);
}

export const confirmaBoletim = async (tip, id) => {
    if (tip) {
        const response = await fetch(API.url + 'bulletins/subscribe/confirm/' + id + '/', {
            method: 'POST',
            //body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        return await response;
    } else {
        const response = await fetch(API.url + 'bulletins/unsubscribe/' + id + '/', {method: 'DELETE'})
        return await response;
    }
}

const sendGenerics = async (url, data) => {
    return fetch(API.url + url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(async (response) => {
        return await response;
    });
}

export default getData;
