import React from 'react';
import {Breadcrumb} from "react-bootstrap"
import './breadcrumbs.css'

const Breadcrumbs = ({pages}) => {
    return (
        <Breadcrumb className="breadcrumb-body">
            <Breadcrumb.Item href="/">INÍCIO</Breadcrumb.Item>
            {pages.Pages.map(p => (
                <Breadcrumb.Item key={p.link} href={p.link} active={p.active}>{p.title}</Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
}

export default Breadcrumbs;