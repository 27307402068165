import React, {useEffect, useState} from 'react';
import {Col, Container, Image, Row} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';
import {Helmet} from "react-helmet-async";
import getData from '../../services.js';
import {formataData} from '../../ui/utils/date';
import LoadingScreen from "../../components/loading/loading";
import './about.css'
import Ops from "../cases/ops";

const AboutUs = () => {
    //Data
    const [aboutUsData, setAboutUsData] = useState(undefined);
    const [documentsData, setDocumentsData] = useState([]);
    const [teamData, setTeamData] = useState([]);
    //Environment
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "QUEM SOMOS",
            "link": "/quem-somos",
            "active": true
        }]
    });

    //Effects
    useEffect(function getAboutUsData() {
        getData('about/').then((resp) => {
            setAboutUsData(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, []);

    useEffect(function getFilesData() {
        getData('reunion/files/').then((resp) => {
            setDocumentsData(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, []);

    useEffect(function getTeamData() {
        getData('groups/').then((resp) => {
            setTeamData(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, []);

    useEffect(function updateTitle() {
        document.title = "OAPS - Quem Somos";
    }, []);

    //Functions
    function renderPresentation() {
        return (
            <Container>
                <Row><h5><b>APRESENTAÇÃO</b></h5></Row>
                <br/>
                <Row>
                    <div dangerouslySetInnerHTML={{__html: aboutUsData.presentation}}/>
                </Row>
            </Container>
        )
    }

    function renderMission() {
        return (
            <Container>
                <Row><h5><b>MISSÃO</b></h5></Row>
                <br/>
                <Row>
                    <div dangerouslySetInnerHTML={{__html: aboutUsData.history}}/>
                </Row>
            </Container>
        )
    }

    function renderGovernance() {
        return (
            <Container>
                <Row><h5><b>GOVERNANÇA</b></h5></Row>
                <br/>
                <Row>
                    <div dangerouslySetInnerHTML={{__html: aboutUsData.governance}}/>
                </Row>
            </Container>
        )
    }

    function printTeam(name, persons) {
        return (
            <Col key={name} xs={12} sm={12} md={12} lg={12} xl={12} className='p-2'>
                <div><b>{name}</b></div>
                <Row>
                    {persons.length > 0 && persons.map((p, i) => (
                        <Col key={p.id + '_' + i} xs={12} sm={6} md={4} lg={4} xl={4} className='p-2 pl-4'>
                            {p.name}
                        </Col>
                    ))}
                </Row>
            </Col>
        )
    }

    function renderTeams() {
        return (
            <Container>
                <Row>
                    <Col className='d-flex justify-content-center' xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Image fluid src={aboutUsData.chart}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Container>
                            <Row><h5><b>EQUIPE</b></h5></Row>
                            <Row>
                                {teamData.map(g => (
                                    printTeam(g.name, g.persons)
                                ))}
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        )
    }

    function printDocumment(item) {
        return (
            <Col key={item.id} xs={12} sm={6} md={6} lg={6} xl={6} className='p-2'>
                <a target='_blank' rel="noreferrer" href={item.file}>
                    {item.title}
                </a>
                <br/>
            </Col>
        )
    }

    function printDocummentWithDate(item) {
        return (
            <Col key={item.id} xs={12} sm={6} md={6} lg={6} xl={6} className='p-2'>
                <a target='_blank' rel="noreferrer" href={item.file}>
                    {item.title}
                    <div className='small'>
                        Postagem: {formataData(item.created_at)}
                    </div>
                </a>
                <br/>
            </Col>
        )
    }

    function renderDocuments() {
        return (
            <Container>
                <Row><h5><b>DOCUMENTOS</b></h5></Row>
                <br/>
                <Row>
                    {documentsData.map(item => (
                        item.type === 'Regimento' ? printDocummentWithDate(item) : ""
                    ))}
                </Row>
                <Row>
                    {documentsData.map(item => (
                        item.type === 'Ata' ? printDocumment(item) : ""
                    ))}
                </Row>
            </Container>
        )
    }

    function container() {
        return (
            <Container className='text-justify about-text-color'>
                {renderPresentation()}
                <br/>
                {renderMission()}
                <br/>
                {renderGovernance()}
                <br/>
                {renderTeams()}
                <br/>
                {renderDocuments()}
            </Container>
        )
    }

    return (
        <>
            <Helmet>
                <meta property="og:title" content="OAPS: Quem Somos" />
                <meta property="og:description" content='Página Quem Somos' />
                <meta property="og:image" content="https://observatorio.analisepoliticaemsaude.org/logo.png" />
                <meta property="og:url" content="https://observatorio.analisepoliticaemsaude.org/quemsomos" />
            </Helmet>
            <PageBreadCrumb pages={breadcrumbs} />
            {ops === 0 && teamData.length > 0 && aboutUsData !== undefined ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    );
}

export default AboutUs;
