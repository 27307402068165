import React, {useState} from 'react';
import {Col, Row, Spinner} from 'react-bootstrap';
import Slider from 'react-slick';
import './calendar.css'
import {Link} from "react-router-dom";


const Calendar = ({list, link_to, functionality, year, month}) => {
    //Environment
    let array_mes = ["0", "JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];
    const [slid, setS] = useState(undefined);
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        focusOnSelect: true,
        rtl: false,
        slidesToShow: 4,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 3,
            }
        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        }
        ]
    };

    /*TODO: Verificar problema no slider das letras grandes demais no celular*/

    //Functions
    function calendar_box(item) {
        if (item !== undefined) {
            let dd = item.split('/');
            return (
                <Link key={item} to={link_to + dd[0] + "/" + dd[1] + "/"}>
                    <div
                        className={parseInt(dd[0]) === parseInt(year) && parseInt(dd[1]) === parseInt(month) ?
                            'event_calendar-item event_calendar-color-selected' :
                            'event_calendar-item event_calendar-color'}>
                        <Col xs={2} sm={2} md={2} lg={2} xl={2}/>
                        <Col className='event_calendar-ano event_calendar-rotate-year' xs={2} sm={2} md={2} lg={2}
                             xl={2}>{dd[0]}</Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <h1 className='event_calendar-h1'>{array_mes[Number(dd[1])]}</h1>
                        </Col>
                    </div>
                </Link>
            )
        }
    }

    function renderSlider() {
        let slideIndex = Number(list.findIndex(s => String(s) === String(year + "/" + month)))
        return (
            <>
                <Row id={"slider_" + functionality}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Slider className="event_calendar-slider" ref={slider => (setS(slider))} {...settings}>
                            {list.length > 0 && list.map(item => (
                                calendar_box(item)
                            ))}
                        </Slider>
                    </Col>
                </Row>
                {list !== undefined && list.length > 0 && slid !== undefined &&
                    slid.slickGoTo(slideIndex)
                }
            </>
        );
    }

    function renderLoading() {
        return (
            <Row>
                {[1, 2, 3, 4].map(k => (
                    <Col key={k} xs={6} sm={4} md={3} lg={3} xl={3}>
                        <Spinner animation="grow" size="md" className="spinner-center"/>
                    </Col>
                ))}
            </Row>
        )
    }

    return (
        <>
            {list.length > 0 ?
                renderSlider()
                :
                renderLoading()
            }
        </>
    );
}

export default Calendar;
