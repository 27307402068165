import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Image, Row, Spinner} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';

import getData, {sendMatriz} from '../../services.js';
import LoadingScreen from "../../components/loading/loading";
import './matrix.css'
import Ops from "../cases/ops";

const breadcrumbs = ({
    "Pages": [{
        "title": "MATRIZES",
        "link": "",
        "active": true
    }]
});

const Matrix = () => {
    const [dataEixos, setDataEixos] = useState([]);

    const [eixoSelected, setEixoSelected] = useState([]);
    const [ano, setAno] = useState([]);

    const [topic, settopic] = useState(false);
    const [mudou, setMudou] = useState(0);
    const [refre, setRefre] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);

    const [jAno, aa] = useState([]);

    const [jTopic, nn] = useState([]);

    const [ops, setOps] = useState(0);

    const mudaImagem = (s) => {
        let eix = eixoSelected.find(x => x.id === s);
        return eix !== undefined;
    }

    const handlerEixoSelected = (s) => {
        let vet = eixoSelected;
        let exist = vet.find(x => x.id === s);

        if (exist !== undefined) {
            vet.splice(vet.indexOf(exist), 1);
            return;
        }

        if (eixoSelected.length < 2)
            vet.push(dataEixos.axis.find(x => x.id === s));
        else {
            alert('só pode no máximo 2');
        }
    }

    const handlerYearsSelected = (f, ev) => {
        let vet = jAno.filter(x => x.isChecked === true);

        if (ev.target.checked && vet.length >= 4) {
            alert('Permitido selecionar no máximo 4 anos.');
            ev.target.checked = false;
            return;
        }

        setAno(ev.target.checked);
        setMudou(f);
    }

    useEffect(() => {
        let t = jTopic;
        let a = jAno;
        getData('axes/matrix/')
            .then((resposta) => {
                resposta.topics.map((bc, index) =>
                    t[index] = {value: bc, isChecked: false},
                );
                resposta.years.map((bc, index) =>
                    a[index] = {value: bc, isChecked: false},
                );
                setDataEixos(resposta);
            })
            .catch((err) => {
                console.log(err.message);
                setOps(1)
            });
    }, []);

    useEffect(() => {
        const result = jAno !== undefined ? jAno.find(f => f.value === mudou) : {};
        if (result !== undefined)
            result.isChecked = !result.isChecked;
        setRefre(!refre);
    }, [ano, mudou]);

    useEffect(() => {
        const result = jTopic !== undefined ? jTopic.find(f => f.value === mudou) : {};
        if (result !== undefined)
            result.isChecked = !result.isChecked;
        setRefre(!refre);
    }, [topic, mudou]);

    useEffect(() => {
        document.title = "OAPS - Matriz de Acompanhamento";
    }, []);

    async function montaJson() {
        let dta = {
            "years": jAno.filter(x => x.isChecked === true).map(s => s.value),
            "axis": eixoSelected.map(x => x.id),
            "topics": jTopic.filter(x => x.isChecked === true).map(s => s.value)
        };
        if (dta.years.length === 0 || dta.axis.length === 0 || dta.topics.length === 0) {
            alert("É necessário selecionar ao menos uma opção de cada escolha!");
            return;
        }
        let body = await sendMatriz(dta).then(res => res.blob())

        const file = new Blob(
            [body],
            {type: 'application/pdf'});

        const fileURL = URL.createObjectURL(file);
        setPdfLoading(false);
        window.open(fileURL);
    }

    function matrix_text() {
        const texto1 = "1. Para obter um relatório contendo a análise sobre os tópicos informados acima, basta selecionar o(s) Eixo(s) Temático(s) de sua escolha (exemplo: eixo Estudos e Políticas sobre Atenção Primária e Promoção da Saúde) e, em seguida, selecionar o(s)  tópico(s)  de  análise  de  seu  interesse.  O  sistema  permitirá  a  visualização  do resultado  da  pesquisa  gerando  um  documento  de  acesso  online (“Veja o resultado da seleção”) ou um documento em pdf para download (“Download do resultado em pdf”).";
        const texto2 = "2. É possível selecionar até dois Eixos Temáticos para pesquisa. Não há limite para seleção do tópico de análise. As análises correspondentes à seleção serão disponibilizadas em um mesmo documento.";
        return (
            <Container className='matrix_container'>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className='dark-blue-text-color'><b>COMO PESQUISAR NA MATRIZ DE ACOMPANHAMENTO</b></p>
                        <p className='matrix-text matrix-text-box'>{texto1}</p>
                        <br/>
                        <p className='matrix-text matrix-text-box'>{texto2}</p>
                    </Col>
                </Row>
            </Container>
        )
    }

    function axis_choice() {
        return (
            <Container className='matrix_container'>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className='dark-blue-text-color'><b>SELECIONE O(S) EIXO(S) DE PESQUISA</b></p>
                    </Col>
                </Row>
                <Row className='matrix_axis-row'>
                    <div className='matrix_axis-box matrix-text matrix-text-box'>
                        {dataEixos.axis !== undefined && dataEixos.axis.map((bc) => (
                            <>
                                <Col className='matrix_axis-flex-display generic_mousePointer matrix_box-margin-padding'
                                     style={{backgroundColor: `${mudaImagem(bc.id) ? bc.color_3 : ''}`}}
                                     xs={10} md={5} sm={5}
                                     onClick={() => {
                                         handlerEixoSelected(bc.id);
                                         setRefre(!refre);
                                     }}>
                                    <Col xs={6} md={6} sm={6}>
                                        <Image fluid alt={bc.title} className='matrix-imagem-box'
                                               src={(mudaImagem(bc.id) ? bc.color_image : bc.transparent_image)}
                                        />
                                    </Col>
                                    <Col className='matrix_axis-title' xs={6} md={6} sm={6}>
                                        {bc.title}
                                    </Col>
                                </Col>
                                <Col>
                                </Col>
                            </>
                        ))}
                    </div>
                </Row>
            </Container>
        )
    }

    function topic_choice() {
        return (
            <Container className='matrix_container'>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className='dark-blue-text-color'>
                            <b>SELECIONE O(S) TÓPICO(S) DE ANÁLISE</b>
                        </p>
                    </Col>
                </Row>
                <Row className="matrix-text matrix-text-box">
                    {jTopic !== undefined && jTopic.map((f) => (
                        <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                            <input type="checkbox" value={f.value} onChange={(ev) => {
                                settopic(ev.target.checked);
                                setMudou(f.value);
                            }}/>{' ' + f.value}
                        </Col>
                    ))}
                </Row>
            </Container>

        )
    }

    function years_choice() {
        return (
            <Container className='matrix_container'>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className='dark-blue-text-color'><b>SELECIONE O(S) ANO(S)</b></p>
                    </Col>
                </Row>
                <Row className="matrix-text matrix-text-box">
                    {jAno !== undefined && jAno.map((f) => (
                        <Col xs={4} sm={4} md={3}>
                            <input type="checkbox" value={f.value} onChange={(ev) => {
                                handlerYearsSelected(f.value, ev);
                                setRefre(!refre);
                            }}/>{' ' + f.value}
                        </Col>
                    ))}
                </Row>
            </Container>
        )
    }

    function choice_list() {
        return (
            <Container className='matrix_container'>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className='dark-blue-text-color'>
                            <b>SELEÇÃO DA MATRIZ DE ACOMPANHAMENTO</b>
                        </p>
                    </Col>
                </Row>
                <Row className="matrix-text matrix-text-box">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className='dark-blue-text-color'><b>EIXO(S) SELECIONADO(S)</b></p>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="matrix-text matrix-text-box">
                        {eixoSelected.map((eixo) => (
                            <p>{
                                eixo.title
                            }</p>
                        ))}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className='dark-blue-text-color'><b>TÓPICO(S) SELECIONADO(S)</b></p>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="matrix-text matrix-text-box">
                        <p>
                            {jTopic.filter(x => x.isChecked === true).map((t, i) => (
                                <>{
                                    i === 0 ? t.value : ', ' + t.value
                                }</>
                            ))}
                        </p>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <p className='dark-blue-text-color'><b>PERÍODO(S) SELECIONADO(S)</b></p>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="matrix-text matrix-text-box">
                        <p>
                            {jAno.filter(x => x.isChecked === true).map((a, i) => (
                                <>{
                                    i === 0 ? a.value : ', ' + a.value
                                }</>
                            ))}
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col/>
                    <Col xs={8} sm={6} md={4} lg={4} xl={4}>
                        <Button onClick={() => {
                            setPdfLoading(true);
                            montaJson();
                        }
                        } hidden={pdfLoading}>DOWNLOAD DO RESULTADO EM PDF
                        </Button>
                        <Button variant="primary" disabled hidden={!pdfLoading}>
                            <Spinner
                              as="span"
                              animation="grow"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                            Criando o arquivo. Por favor, aguarde...
                      </Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    function container() {
        return (
            <>
                <Container>
                    {matrix_text()}
                    {axis_choice()}
                    {topic_choice()}
                    {years_choice()}
                    {choice_list()}
                </Container>
            </>
        )
    }

    return (
        <>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && dataEixos !== undefined ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    )
}

export default Matrix;
