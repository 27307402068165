import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Container, Row} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';
import {Helmet} from "react-helmet-async";
import getData from '../../services.js';
import LoadingScreen from "../../components/loading/loading";
import DataInstance from "../../components/contentDataInstance/dataInstance";
import Comments from "../../components/comments/comments";
import GridOtherItems from "../../components/gridAndList/gridItems";
import Ops from "../cases/ops";

const Debates = ({match}) => {
    //Data
    const [instance, setInstance] = useState(undefined);
    //Environment
    const history = useHistory();
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "DEBATES",
            "link": "/debates/",
            "active": true
        }]
    });

    //Effects
    useEffect(function getInstanceFromServer() {
        let uri = match.params.id === undefined ?
            'debates/principal/' :
            'debates/id/' + match.params.id + '/'
        getData(uri).then((resp) => {
            setInstance(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, [match.params.id]);

    useEffect(function updateTitle() {
        if (instance !== undefined) {
            document.title = "OAPS - Debate: " + instance.title;
            let uri = '/debate/' + instance.id + '/'
            history.push(uri);
        }
    }, [instance]);

    //Functions
    function container() {
        return (
            <Container fluid id={"debate_" + match.params.id}>
                <DataInstance
                    instance={instance}
                    link_to={"/debate/" + match.params.id + "/"}
                    enconded_link={"%2Fdebate%2F" + match.params.id + "%2F"}
                />
                <br/>
                <Comments id={instance.id} functionality="debates" comments={instance.comments}/>
                <Row className='blue-line-section-separator'/>
                <GridOtherItems functionality="debates" link="/debate/"/>
            </Container>
        );
    }

    return (
        <>
            <Helmet>
                <meta property="og:title" content="OAPS: Debates" />
                <meta property="og:description" content={(instance !== undefined) ? instance.title : 'Título do Debate'} />
                <meta property="og:image" content={(instance !== undefined) ? instance.image + '?w=800' : 'https://observatorio.analisepoliticaemsaude.org/logo.png'} />
                <meta property="og:url" content={(instance !== undefined)
                                                  ? ('https://observatorio.analisepoliticaemsaude.org/debate/' + instance.id + '/')
                                                  : 'https://observatorio.analisepoliticaemsaude.org/debate/'} />
            </Helmet>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && instance !== undefined ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    );
}

export default Debates;
