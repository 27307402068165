import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Col, Container, Image, Row} from 'react-bootstrap';
import img_topo from './imgs/logo.png';
import iCarta from './imgs/icones/envelop.png';
import iTwitter from './imgs/icones/twitter.png';
import iFace from './imgs/icones/face.png';
import iYoutube from './imgs/icones/yt.png';
import iRSS from './imgs/icones/rss.png';
import iLupa from './imgs/icones/lupa.png';
import Modal from '../components/modals/contato';

const Topo = () => {
    const [modalShow, setModalShow] = useState(false);

    return (
        <Container>
            <Row>
                <Col xs={5} md={5} sm={5}>
                    <Link to="/">
                        <Image src={img_topo} fluid rounded/>
                    </Link>
                </Col>
                <Col/>
                <Col xs={6} md={4} sm={6}
                     style={{textAlign: 'end'}}>
                    <Link to="/busca">
                        <Image className='icones' src={iLupa} fluid/>
                    </Link>
                    <Image className='icones generic_mousePointer' onClick={() => setModalShow(true)} src={iCarta}
                           fluid/>
                    <a target="_blank" rel="noopener noreferrer" href="https://rss.com/">
                        <Image className='icones' src={iRSS} fluid/>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/analisepoliticaemsaude">
                        <Image className='icones' src={iFace} fluid/>
                    </a>
                    <a target="_blank" rel="noopener noreferrer"
                       href="https://www.youtube.com/channel/UCJGoyElAlBUk32S3BnRuyQg">
                        <Image className='icones' src={iYoutube} fluid/>
                    </a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/Oaps_saude">
                        <Image className='icones' src={iTwitter} fluid/>
                    </a>
                </Col>
            </Row>
            <Modal show={modalShow} onHide={() => setModalShow(false)}/>
        </Container>
    );
};

export default Topo;
