import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Image, Row, Spinner} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';

import getData from '../../services.js';
import LoadingScreen from "../../components/loading/loading";
import './newspapers.css'
import Ops from "../cases/ops";

function Newspapers() {
    //Data
    const [next, setNext] = useState(null);
    const [itemList, setItemList] = useState([]);
    const [isLoading, setLoading] = useState(true);
    //Environment
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "TÁ NA MÍDIA",
            "link": "/ta-na-midia/",
            "active": true
        }]
    });
    //Page
    const handleClick = () => {
        setLoading(true)
    };

    //Effects
    useEffect(function getMoreNewspapers() {
        function networkRequest() {
            let getDataParam = next === null ?
                'newspapers/'
                :
                'newspapers/?page=' + next.split("=")[1]
            return getData(getDataParam).then((resp) => {
                setNext(resp.next);
                let vec = itemList
                vec = vec.concat(resp.results)
                setItemList(vec);
            }).catch((err) => {
                console.log(err.message);
                setOps(1)
            });
        }

        if (isLoading) {
            networkRequest().then(() => {
                setLoading(false);
            });
        }
    }, [isLoading, itemList, next]);

    useEffect(function updateTitle() {
        document.title = "OAPS - Tá na Mídia";
    }, []);

    //Functions
    function blockButton() {
        return (
            <Row>
                <Col className="d-grid gap-2" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button size="lg" variant="outline-primary newspapers-full-button" disabled>
                        Limite atingido. 100/100
                    </Button>
                </Col>
            </Row>
        )
    }

    function loadingButton() {
        return (
            <Row>
                <Col className="d-grid gap-2" xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Button size="lg" variant="outline-primary newspapers-full-button"
                            disabled={isLoading} onClick={!isLoading ? handleClick : null}>
                        {isLoading ? 'Carregando…' : 'Ver mais (' + itemList.length.toString() + '/100)'}
                    </Button>
                </Col>
            </Row>
        )
    }

    function spinnerLoading() {
        return (
            <Row>
                {[1, 2, 3, 4].map(item => (
                    <Col key={item} xs={12} sm={6} md={3} lg={3} xl={3}>
                        <Spinner animation="grow" size="md" className="spinner-center newspapers-margin"/>
                    </Col>
                ))}
            </Row>
        )
    }

    function printItem(item) {
        return (
            <Col key={item.id} xs={12} sm={12} md={3} lg={3} xl={3} className="newspapers-margin">
                <a href={item.url} target="_blank" rel="noreferrer">
                    <Image fluid src={item.image} rounded alt={item.alt_image} style={{width: '200%'}}/>
                </a>
            </Col>
        )
    }

    function container() {
        return (
            <Container fluid>
                <Row>
                    {itemList.map(item => (
                        printItem(item)
                    ))}
                </Row>
                {isLoading &&
                    spinnerLoading()
                }
                {itemList.length < 100 && next !== null ?
                    loadingButton()
                    :
                    blockButton()
                }
            </Container>
        )
    }

    return (
        <>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && itemList.length > 0 ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    )
}

export default Newspapers;
