import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {Col, Container, Image, Row} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';
import headerBoletim from '../../ui/imgs/BOLETIM_TOPONOVO.png';
import getData from '../../services.js';
import LoadingScreen from "../../components/loading/loading";
import defaultImageRetangular from "../../ui/imgs/default/retangular.jpg";
import Ops from "../cases/ops";
import './boletim.css'

const Boletim = ({match}) => {
    //Data
    const [anos, setAnos] = useState([]);
    const [dataBoletim, setDataBoletim] = useState(undefined);
    const [dataEquipe, setDataEquipe] = useState([]);
    //Environment
    const history = useHistory();
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "BOLETINS",
            "link": "/boletins/",
            "active": false
        }, {
            "title": "BOLETIM",
            "link": "",
            "active": true
        }]
    });

    //Effects
    useEffect(function getYearsFromServer() {
        getData('bulletins/years/').then((resp) => {
            setAnos(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, []);

    useEffect(function getInstanceFromServer() {
        if (match !== undefined && match.params.id !== undefined) {
            getData('bulletins/' + match.params.id + '/').then((resp) => {
                setDataBoletim(resp);
            }).catch((err) => {
                console.log(err.message);
                setOps(1)
            });
        }
    }, []);

    useEffect(function getTeamFromServer() {
        getData('groups/').then((resp) => {
            setDataEquipe(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, []);

    useEffect(function updateTitle() {
        if (dataBoletim !== undefined && dataBoletim.topics !== undefined && history) {
            document.title = "OAPS - Boletim: " + dataBoletim.topics[0].title;
            let uri = '/boletim/' + dataBoletim.year + '/' + dataBoletim.id;
            history.push(uri);
        }
    }, [dataBoletim]);

    //Functions
    function renderTeam() {
        return (
            <Row className=''>
                <Col className='py-3 bulletin_base bulletin-colors'>
                {dataEquipe !== undefined && dataEquipe.map(carg => (
                    <Col key={carg.id} className='py-2' xs={12} md={12} sm={12}>
                        <Row>
                        <strong>{carg.name + ': '}</strong>
                        {carg.persons !== undefined && carg.persons.map((pes, index) => (
                            <div key={pes.name + index}>{index === 0 ? pes.name + " " : ' | ' + pes.name}</div>
                        ))}
                        </Row>
                    </Col>
                ))}
              </Col>
            </Row>
        )
    }

    function renderTopic(topic) {
        return (
            <>
            <Row id={topic.id} className='bulletin_base bulletin-body'>
                <Col className='flex bulletin-topics' xs={12} md={12} sm={12}>
                    <p>
                        {topic.image !== null &&
                           <Image fluid src={topic.image}
                               alt={topic.image_legend || ''} className="flex"/>
                        }
                        <div className='bulletin-topic-title pt-4'><strong>{topic.title}</strong></div>
                        <div className='text-justify'
                             dangerouslySetInnerHTML={{__html: topic.body}}/>
                    </p>
                </Col>
            </Row>
                </>
        )
    }

    function renderPrincipal() {
        return (
            <Row className='bulletin_base bulletin-body'>
                <Col xs={12} md={12} style={{marginBottom: '5px'}} sm={12}>
                    <div className='text-justify' dangerouslySetInnerHTML={{__html: dataBoletim.presentation}}/>
                </Col>
                <Col xs={12} md={12} sm={12}>
                    {/*dataBoletim*/}
                    <Container>
                        <Row id={dataBoletim.topics[0].id}>
                            <Col className='bulletin-topic-title' xs={12} md={12} sm={12}>
                                <h2><b>{dataBoletim.topics !== undefined && (dataBoletim.topics[0].title)}</b></h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} sm={12}>
                                <Image width='100%'
                                       src={dataBoletim.topics !== undefined && (dataBoletim.topics[0].image || defaultImageRetangular)}
                                       alt={dataBoletim.topics !== undefined && (dataBoletim.topics[0].image_legend)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} sm={12}>
                                {dataBoletim.topics !== undefined &&
                                    <div className='text-justify' dangerouslySetInnerHTML={{__html: dataBoletim.topics[0].body}}/>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        )
    }

    function renderHeader() {
        return (
            <>
                <Row className='bulletin_base bulletin-colors bulletin-header-font'>
                    <Col xs={6} md={6} className='mt-2' sm={6}>
                        ANO: {dataBoletim.volume} &#8226;
                        EDIÇÃO Nº {dataBoletim.number} &#8226; {dataBoletim.month_opt.toUpperCase()} {dataBoletim.year}
                    </Col>
                    <Col/>
                    <Col xs={5} md={4} className='mt-2 text-right' sm={4}>
                        <a href={dataBoletim.file} rel="noreferrer" target='_blank'>IMPRIMIR</a> | <a href={dataBoletim.file}
                                                                                     rel="noreferrer" target='_blank'>BAIXAR
                        BOLETIM</a>
                    </Col>
                </Row>
                <Row className='bulletin_base bulletin-header-colors'>
                    <Col className='mb-2' xs={12} md={12} sm={12}>
                        <Image fluid src={headerBoletim} alt={'Boletim Observatório'} width='100%'/>
                    </Col>
                </Row>
            </>
        )
    }

    function renderBoletim() {
        return (
            <Container id='boletim'>
                {renderHeader()}
                <Row>
                    <Col xs={12} md={12} sm={12}>
                        <Container className='bulletin-container'>
                            {renderPrincipal()}
                            {dataBoletim.topics !== null && dataBoletim.topics.slice(1, dataBoletim.topics.length).map(topic => (
                                <div key={topic.id}>
                                    {renderTopic(topic)}
                                </div>
                            ))}
                            {renderTeam()}
                        </Container>
                    </Col>
                </Row>
            </Container>
        )
    }

    function container() {
        return (
            <Container>
                <Row>
                    <Col xs={12} md={12} sm={12}>
                    </Col>
                </Row>
                {renderBoletim()}
            </Container>
        )
    }

    return (
        <>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && anos.length > 0 && dataBoletim !== undefined ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    )
}

export default Boletim;
