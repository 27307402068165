import React, {useEffect, useState} from 'react';
import {Col, Container, Image, Pagination, Row, Spinner} from 'react-bootstrap';
import getData from "../../services";
import './gridItem.css';

let page = '';
let vv = '';

const GridItems = ({functionality, link}) => {
    const [instances, setOutrasInstancias] = useState([]);
    let [show, setShow] = useState(false);
    const [seta, setSeta] = useState(true);
    const [prev, setPrev] = useState(null);
    const [next, setNext] = useState(null);
    const [actualPage, setActualPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    //TODO: Adicionar uma variavel que indique a quantidade de paginações possiveis na total page
    useEffect(() => {
        getData(functionality + '/' + page).then((data) => {
            setNext(data.next);
            setPrev(data.previous);
            setTotalPage(data.count);
            setOutrasInstancias(data.results);
            if (next !== null) {
                let n = data.next.split("=")[1]
                setActualPage(parseInt(n) - 1)
            } else if (prev !== null){
                let p = data.previous.split("=")[1]
                setActualPage(parseInt(p) + 1)
            }
        })
            .catch((err) => {
                console.log(err.message);
            });
    }, [seta]);

    /*TODO: exibir menos itens em telas pequenas (1 ou 2)*/
    /*TODO: Buscar uma forma de carregar o componente sem carregar o site novamente*/

    function handlerButtonNext() {
        if (next !== null) {
            vv = next.split("=");
            page = vv[1] !== undefined ? '?page=' + vv[1] : '';
            setSeta(!seta);
        }
    }

    function handlerButtonPrev() {
        if (prev !== null) {
            vv = prev.split("=");
            page = vv[1] !== undefined ? '?page=' + vv[1] : '';
            setSeta(!seta);
        }
    }

    // function cutAndPrint(title) {
    //     if (title && title.length > 70) {
    //         let cutTitle = title.slice(0, 70);
    //         return <div className="griItem_legend gridItem_legend-show">{cutTitle + "..."}</div>
    //     } else {
    //         return <div className="griItem_legend gridItem_legend-show">{title}</div>
    //     }
    //
    // }

    function printItem(item) {
        return (
            <Col key={item.id} xs={12} sm={6} md={4} lg={4} xl={4} className="griItem_margin-topBottom">
                <a href={link + item.id}>
                    <Image fluid onClick={() => {
                        setShow(!show);
                    }} src={item.image}/>
                    <div className="gridItem_legend-on-hover">
                        {/*{cutAndPrint(item.title)}*/}
                    </div>
                </a>
            </Col>
        )
    }

    function gridSlice() {
        return (
            <Row>
                {instances.slice(0, 3).map((item) => (
                    printItem(item)
                ))}
            </Row>
        )
    }

    function gridComplete(){
        return (
            <Row className='griItem_showButton'>
                <div className='griItem_content generic_mousePointer'>
                    {instances.map((item, index) => (
                        printItem(item)
                    ))}
                </div>
                {paginator()}
            </Row>
        )
    }

    function paginator() {
        return (
            <Container>
                <Row>
                    <Col>
                        <Pagination className='justify-content-center'>
                            {actualPage > 1 &&
                                <>
                                    <Pagination.Prev onClick={() => handlerButtonPrev()}/>
                                    <Pagination.Item onClick={() => handlerButtonPrev()}>{actualPage - 1}</Pagination.Item>
                                </>
                            }

                            <Pagination.Item active>{actualPage}</Pagination.Item>

                            {actualPage < totalPage &&
                                <>
                                    <Pagination.Item onClick={() => handlerButtonNext()}>{actualPage + 1}</Pagination.Item>
                                    <Pagination.Next onClick={() => handlerButtonNext()}/>
                                </>
                            }
                        </Pagination>
                    </Col>
                </Row>
            </Container>
        )
    }

    function renderGrid() {
        return (
            <>
                {show ?
                    gridComplete()
                :
                    gridSlice()
                }
                <Row className='generic_mousePointer'>
                    <div onClick={() => setShow(!show)}>{show ? 'Esconder' : 'Ver todas'}</div>
                </Row>
            </>
        )
    }

    function renderLoading() {
        return (
            <Row>
                {[1, 2, 3].map(k => (
                    <Col key={k} xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Spinner animation="grow" size="md" className="spinner-center"/>
                    </Col>
                ))}
            </Row>
        )
    }

    return (
        <>
            {instances.length > 0 ?
                renderGrid()
                :
                renderLoading()
            }
        </>
    );
}

export default GridItems;
