import React from 'react';
import {Col, Container, Image, Row, Spinner} from "react-bootstrap"
import logo from "../../ui/imgs/logoBola.png";

const padrao = 'Carregando informações. Aguarde!'

const LoadingScreen = ({hidden, msg}) => {
    return (
        <Container fluid hidden={hidden}>
            <main className="center align-content-center">
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Image fluid src={logo} className="mx-auto" alt={msg === undefined ? padrao : msg }/>
                    </Col>
                </Row>
                <Row className="loading-text">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <b><h3>{msg === undefined ? padrao : msg }</h3></b>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Spinner animation="grow" size="md" className="d-flex spinner-center spinner-size-lg"/>
                    </Col>
                </Row>
            </main>
        </Container>
    );
}

export default LoadingScreen;
