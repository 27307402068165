import React, {useState} from 'react';
import {Col, Row, Spinner} from 'react-bootstrap';
import Slider from "react-slick";
import './gridYears.css'
import {Link} from "react-router-dom";

const GridYears = ({yearsList, link_to, currentYear}) => {
    const [slid, setS] = useState(undefined);
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        focusOnSelect: true,
        rtl: false,
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 0,

        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        }, {
            breakpoint: 280,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        }]
    };

    function renderLoading() {
        return (
            <Row>
                {[1, 2, 3].map(v => (
                    <Col key={v} xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Spinner animation="grow" size="md" className="spinner-center"/>
                    </Col>
                ))}
            </Row>
        )
    }

    /*TODO: No responsivo lista não esta aparecendo*/

    function renderGrid() {
        return (
            <>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Slider ref={slider => (setS(slider))} className="grid_year-flex" {...settings}>
                            {yearsList.map(y => (
                                <Link key={y} to={link_to + y + '/'}>
                                    <h5
                                        className={parseInt(y) === parseInt(currentYear) ?
                                            'grid_year-box grid_year-current'
                                            :
                                            'grid_year-box grid_year-color'
                                        }>
                                        <b>{y}</b>
                                    </h5>
                                </Link>
                            ))}
                        </Slider>
                    </Col>
                </Row>
                {yearsList.length > 0 && slid !== undefined &&
                    slid.slickGoTo(Number(yearsList.findIndex(y => parseInt(y) === parseInt(currentYear))))
                }
            </>
        );
    }

    return (
        <>
            {yearsList.length > 0 ?
                renderGrid()
                :
                renderLoading()
            }
        </>
    );
}

export default GridYears;
