import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import CommentForm from "./commentForm";
import CommentsList from "./commentsList";
import './comments.css'

const Comments = ({id, functionality, comments}) => {
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} >
                <Container className="comments_box comments_box-backgroud-color" >
                    <CommentForm functionality={functionality} belongs_to={id} />
                    <br/>
                    <div className='comments_border-top' />
                    <br/>
                    <CommentsList comments={comments} />
                </Container>
            </Col>
        </Row>
    );
}

export default Comments;
