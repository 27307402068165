import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import './cases.css'

const Ops = () => {
    //Effects
    useEffect(() => {
        document.title = "OAPS - Algo errado";
    }, []);

    return (
        <Container className='cases-background-white-color cases-other-page'>
            <Row>
                <Col className='text-center' xs={12} md={12} sm={12}>
                    <h1>Ops...</h1>
                </Col>
                <Col className='text-center' xs={12} md={12} sm={12}>
                    <h5>Algo de errado aconteceu. Por favor recarregue a página</h5>
                </Col>
                <Col className='text-center' xs={12} md={12} sm={12}>
                    <h5>Se o erro persistir, senta e chora.</h5>
                </Col>
                <Col className='text-center' xs={12} md={12} sm={12}>
                    Voltar para a <Link to={'/'}>Home</Link>
                </Col>
            </Row>
        </Container>
    );
}

export default Ops;
