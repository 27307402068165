import React, {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';
import getData from '../../services.js';
import InterviewsList from "./interviewsList";
import GridYears from "../../components/gridAndList/gridYears";
import LoadingScreen from "../../components/loading/loading";
import {useHistory} from "react-router-dom";
import Ops from "../cases/ops";

const Interviews = ({match}) => {
    //Data
    const [yearsList, setYearsList] = useState([]);
    const [instanceList, setInstanceList] = useState([]);
    const [currentYear, setCurrentYear] = useState(undefined);
    //Environment
    const history = useHistory();
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "ENTREVISTAS",
            "link": "",
            "active": true
        }]
    });

    //Effects
    useEffect(function getInstancesFromServer() {
        if (currentYear !== undefined) {
            getData('interviews/years/' + currentYear + '/')
                .then((resp) => {
                    setInstanceList(resp);
                }).catch((err) => {
                    console.log(err.message);
                    setOps(1)
                });
        }
    }, [currentYear]);

    useEffect(function getYearsFromServer() {
        getData('interviews/years/')
            .then((resp) => {
                setYearsList(resp);
                let year = match.params.year === undefined ?
                    resp[resp.length - 1] : match.params.year;
                setCurrentYear(year);
            }).catch((err) => {
                console.log(err.message);
                setOps(1)
            });
    }, [match.params.year]);

    useEffect(function updateTitle() {
        if (instanceList !== undefined && instanceList.length > 0 && currentYear !== undefined) {
            document.title = "OAPS - Entrevistas de " + currentYear;
            history.push('/entrevistas/' + currentYear + '/');
        }
    }, [instanceList]);

    //Functions
    function container() {
        return (
            <Container>
                <GridYears yearsList={yearsList} currentYear={currentYear} link_to="/entrevistas/"/>
                <InterviewsList itemsList={instanceList} link_to={'/entrevista/'} currentYear={currentYear} />
            </Container>
        )
    }

    return (
        <>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && yearsList.length > 0 && instanceList.length > 0 ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    );
}

export default Interviews;
