import React, {useEffect, useState} from 'react';
import {Accordion, Card, Col, Container, Image, Media, Row} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';
import getData from '../../services.js';
import LoadingScreen from "../../components/loading/loading";
import './usefulLinks.css'
import Ops from "../cases/ops";

const Index = () => {
    //Data
    const [dataLinks, setDataLinks] = useState([]);
    //Environment
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "LINKS ÚTEIS",
            "link": "/links",
            "active": true
        }]
    });
    let cc = -1;
    const slat = dataLinks.length % 2 === 0 ? Math.floor(dataLinks.length / 2) : Math.floor(dataLinks.length / 2) + 1;

    //Effects
    useEffect(function getUserfulLinks() {
        getData('useful_links/').then((resp) => {
            setDataLinks(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, []);

    useEffect(function updateTitle() {
        document.title = "OAPS - Links Úteis";
    }, []);

    //Functions
    function renderItem(cat) {
        return(
            <div key={cat.title}>
                <Accordion.Toggle
                    as={Card.Header} eventKey={'' + ++cc}
                    className="links_accordion-header generic_mousePointer"
                >
                    {cat.title}
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={'' + cc}>
                    <Container className="links_accordion-container">
                        <ul className="list-unstyled">
                            {cat.links.map(link => (
                                <Media key={link.id} as="li" className="links_accordion-media">
                                    <Image
                                        width={64}
                                        height={64}
                                        className="mr-3"
                                        src={link.image}
                                        alt="Generic placeholder"
                                    />
                                    <Media.Body className="links_accordion-body">
                                        <h6 className="links_accordion-title">{link.title}</h6>
                                        <a href={link.url}
                                           className="links_accordion-url">{link.url}</a>
                                        <br/>
                                    </Media.Body>
                                </Media>
                            ))}
                        </ul>
                    </Container>
                </Accordion.Collapse>
            </div>
        )
    }

    function leftColumn() {
        return (
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Accordion defaultActiveKey="0">
                    {dataLinks.slice(0, slat).map((cat) => (
                        renderItem(cat)
                    ))}
                </Accordion>
            </Col>
        )
    }

    function rightColumn() {
        return (
            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Accordion defaultActiveKey="0">
                    {dataLinks.slice(slat, dataLinks.length).map((cat) => (
                        renderItem(cat)
                    ))}
                </Accordion>
            </Col>
        )
    }

    function container() {
        return (
            <Container fluid id="links">
                <Row>
                    {leftColumn()}
                    {rightColumn()}
                </Row>
            </Container>
        )
    }

    return (
        <>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && dataLinks.length > 0 ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    );
}

export default Index;
