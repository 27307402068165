import React from "react";
import {Redirect, Route, Switch} from 'react-router-dom';

import Home from '../pages/home/home';

import QuemSomos from "../pages/quemSomos/about";

import Axis from '../pages/eixos/axis';
import EixoApresentacao from "../pages/eixos/apresentacao";
import EixoProducoes from "../pages/eixos/producoes";
import EixoMatriz from "../pages/eixos/matriz";

import Matriz from "../pages/matriz/matrix";

import TaNaMidia from '../pages/taNaMidia/newspapers';
import Index from '../pages/linksUteis/usefulLinks';
import Debate from '../pages/debates/debate';
import Entrevistas from '../pages/entrevistas/interviews';
import Interview from '../pages/entrevistas/interview';
import Noticia from '../pages/noticias/news';
import Eventos from '../pages/eventos/events';
import Evento from '../pages/eventos/event';


import Boletins from '../pages/boletins/boletins';
import Boletim from '../pages/boletins/boletim';
import BoletinsConfirmacao from "../pages/boletins/subscribe";
import BoletinsNotificacao from "../pages/boletins/unsubscribe";

import Miss from '../pages/cases/404';
import Development from "../pages/cases/development";

const Page = () => (
    <Switch>
        <Route exact path="/" component={() => <Home/>}/>

        <Route exact path="/quem-somos" component={QuemSomos}/>
        {/*Eixos*/}
        <Route exact path="/eixos/" component={Axis}/>
        <Route exact path="/eixos/:id/" component={EixoApresentacao}/>
        <Route path="/eixos/:id/apresentacao" component={EixoApresentacao}/>
        <Route path="/eixos/:id/producoes" component={EixoProducoes}/>
        <Route path="/eixos/:id/matriz" component={EixoMatriz}/>
        <Route path="/eixos/:id/linha-do-tempo" component={Development}/>
        <Route path="/matrizes" component={Matriz}/>
        {/* Entrevistas */}
        <Route path="/entrevistas/:year/" component={Entrevistas}/>
        <Route path="/entrevista/:year/:id/" component={Interview}/>
        <Route path="/entrevistas/" component={Entrevistas}/>
        {/* Eventos */}
        <Route path="/eventos/:year/:month/" component={Eventos}/>
        <Route path="/evento/:year/:month/:id/" component={Evento}/>
        <Route exact path="/evento/:id/" component={Evento}/>
        <Route path="/eventos/" component={Eventos}/>
        {/* Boletim */}
        <Route exact path="/boletins/:year/" component={Boletins}/>
        <Route exact path="/boletins/desinscrever/:id" component={BoletinsNotificacao}/>
        <Route exact path="/boletins/inscricao/confirmar/:id/" component={BoletinsConfirmacao}/>
        <Route path="/boletim/:year/:id/" component={Boletim}/>
        <Route exact path="/boletim/:id/" component={Boletim}/>
        <Route path="/boletins/" component={Boletins}/>
        {/* Ta Na Mídia */}
        <Route path="/ta-na-midia/" component={TaNaMidia}/>
        {/* Links Úteis */}
        <Route path="/links/" component={Index}/>
        {/*Debates*/}
        <Route path="/debates/" component={Debate}/>
        <Route path="/debate/:id/" component={Debate}/>
        {/*Noticias*/}
        <Route path="/noticias/" component={Noticia}/>
        <Route path="/noticia/:id/" component={Noticia}/>
        {/*Busca*/}
        <Route path="/busca" component={Development}/>
        {/*Media*/}
        <Route path="/media/*" component={Development}/>
        {/*Redirecionamento*/}
        <Redirect from="/oaps/noticias/:id" to="/noticia/:id"/>
        <Redirect from="/oaps/pensamentos/:id" to="/debate/:id"/>
        <Redirect from="/oaps/boletim/edicao/:id" to="/boletim/:id"/>
        <Redirect from="/oaps/agenda/" to="/eventos"/>

        <Route path="*" component={Miss}/>
    </Switch>
);

export default Page;
