import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Container, Row} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';
import {Helmet} from "react-helmet-async";
import getData from '../../services.js';
import DataInstance from "../../components/contentDataInstance/dataInstance";
import Comments from "../../components/comments/comments";
import LoadingScreen from "../../components/loading/loading";
import GridOtherItems from "../../components/gridAndList/gridItems";
import Ops from "../cases/ops";

const News = ({match}) => {
    //Data
    const [instance, setInstance] = useState(undefined);
    //Environment
    const history = useHistory();
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "NOTÍCIAS",
            "link": "",
            "active": true
        }]
    });

    //Effects
    useEffect(function getInstanceFromServer() {
        let getDataParam = match === undefined || match.params.id === undefined ?
            'news/principal/'
            :
            'news/id/' + match.params.id + '/'
        getData(getDataParam).then((resp) => {
            setInstance(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, []);

    useEffect(function updateTitle() {
        if (instance !== undefined) {
            history.push('/noticia/' + instance.id + '/');
            document.title = "OAPS - Notícia: " + instance.title;
        }
    }, [instance]);

    //Functions
    function container() {
        return (
            <Container fluid id={"news_" + match.params.id}>
                <DataInstance
                    instance={instance}
                    link_to={"/noticia/" + match.params.id + "/"}
                    enconded_link={"%2Fnoticia%2F" + match.params.id + "%2F"}/>
                <br/>
                <Comments id={instance.id} functionality="news" comments={instance.comments}/>
                <Row className='blue-line-section-separator'/>
                <GridOtherItems functionality="news" link="/noticia/"/>
            </Container>
        )
    }

    return (
        <>
            <Helmet prioritizeSeoTags>
                <meta property="og:title" content="OAPS: Notícias" />
                <meta property="og:description" content={(instance !== undefined) ? instance.title : 'Título da Notícia'} />
                <meta property="og:image" content={(instance !== undefined) ? instance.image : '/logo.png'} />
                <meta property="og:url" content={(instance !== undefined)
                                                  ? ('https://observatorio.analisepoliticaemsaude.org/noticia/' + instance.id + '/')
                                                  : 'https://observatorio.analisepoliticaemsaude.org/noticias/'} />
            </Helmet>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && instance !== undefined ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    );
}

export default News;
