import React, {useState} from 'react';
import {Button, Col, Container, Modal, Row, Spinner} from 'react-bootstrap';
import {useFormik} from 'formik';

import {sendContatoMessage} from '../../services';

const validate = values => {
    const errors = {};
    if (!values.name) {
        errors.name = '* Nome é Obrigatório';
    } else if (values.name.length <= 4) {
        errors.name = 'Tamanho do nome insuficiente!';
    }

    if (!values.email) {
        errors.email = '* E-mail é Obrigatório';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'E-mail está inválido!';
    }

    if (!values.subject) {
        errors.subject = '* Assunto é Obrigatório';
    } else if (values.subject.length <= 4) {
        errors.subject = 'Tamanho do assunto insuficiente!';
    }

    if (!values.text) {
        errors.text = '* Mensagem é Obrigatória';
    } else if (values.text.length <= 10) {
        errors.text = 'Tamanho da mensagem insuficiente!';
    }

    return errors;
};

const Contato = (props) => {
    const [load, setLoad] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            text: '',
        },
        validate,
        onSubmit: value => {
            setLoad(true);
            sendContatoMessage(value).then(async result => {
                let a = await result.json();
                setLoad(false);
                alert(a.message);

                if (result.ok && result.status !== 203) {
                    formik.resetForm();
                    props.onHide();
                }

                return a;
            });
        },
    });

    return (
        <Modal {...props}
               size="lg"
               centered
               aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton>
                <Modal.Title className='w-100' id="contained-modal-title-vcenter">
                    <h3 className='text-center'>Entre em contato com nossa equipe</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <form hidden={load} onSubmit={formik.handleSubmit}>
                    <Container className='cadastroBoletim'>
                        <Row>
                            <Col xs={12} md={12} sm={12}>
                                <input className='campo-texto mt1' id="name" name="name" placeholder="Nome"
                                       type="text" onChange={formik.handleChange} value={formik.values.name}
                                />
                                {formik.errors.name ? <label className='pl-4'>{formik.errors.name}</label> : null}
                            </Col>
                            <Col xs={12} md={12} sm={12}>
                                <input className='campo-texto mt-1' id="email" name="email" placeholder="E-mail"
                                       type="email" onChange={formik.handleChange} value={formik.values.email}
                                />
                                {formik.errors.email ?
                                    <label className='pl-4'>{formik.errors.email}</label> : null}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={12} sm={12}>
                                <input className='campo-texto mt-1' id="subject" name="subject" placeholder="Assunto"
                                       type="text" onChange={formik.handleChange} value={formik.values.subject}
                                />
                                {formik.errors.subject ?
                                    <label className='pl-4'>{formik.errors.subject}</label> : null}
                            </Col>
                            <Col xs={12} md={12} sm={12}>
                                <textarea className='campo-texto msg-contato mt-1' id="text" name="text" placeholder="Mensagem"
                                          onChange={formik.handleChange} value={formik.values.text}
                                />
                                {formik.errors.text ? <label className='pl-4'>{formik.errors.text}</label> : null}
                            </Col>
                        </Row>
                        <br/>
                    </Container>
                </form>
                <Spinner hidden={!load} animation="grow" size="md" className="spinner-center ta-na-midia-margin"/>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col>
                        </Col>
                        <Col xs={4} md={3} sm={3}>
                            <Button className='w-100' onClick={() => {
                                formik.resetForm();
                                props.onHide();
                            }}>
                                Cancelar
                            </Button>
                        </Col>
                        <Col xs={4} md={3} sm={3}>
                            <Button className='w-100' onClick={formik.handleSubmit}>Enviar</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
}

export default Contato;
