import React from 'react';
import {Col, Container, Image, Row} from 'react-bootstrap';
import clip from '../../ui/imgs/icones/ESCUROS_0004_CLIP.png'

const Files = ({fileList}) => {
    return (
        <Container>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                {fileList !== undefined && fileList.map(file => (
                    <Row key={file.id}>
                        <p>
                            <Image fluid src={clip} alt={file.alt_file} width={30}/>
                            <a target='_blank' rel="noopener noreferrer" href={file.file}>
                                {file.alt_file}
                            </a>
                        </p>
                    </Row>
                ))}
            </Col>
        </Container>
    );
}

export default Files;
