import React from 'react';
import {Col, Container, Image, Row} from 'react-bootstrap';
import './interviewsList.css'
import defaultImageRetangular from "../../ui/imgs/default/retangular.jpg";
import {Link} from "react-router-dom";
import {getMonthNameAndDay} from '../../ui/utils/date'

const InterviewsList = ({itemsList, link_to, currentYear}) => {
    //Page
    const slat = itemsList.length % 2 === 0 ? 2 : 3;

    //Functions
    function renderItem(item) {
        return (
            <Col xs={12} sm={6} md={6} lg={6} xl={6} key={item.id} className="interview_list-side-box-margin">
                <Link to={link_to + currentYear + '/' + item.id}>
                    <Image fluid src={item.image} alt={item.image_legend} style={{width: '200%'}}/>
                </Link>
            </Col>
        )
    }

    function renderSlat() {
        return (
            <>
                {itemsList.length > 0 && itemsList.slice(1, slat).map(v => (
                    <Link key={v.id} to={link_to + currentYear + '/' + v.id}>
                        <Row className="interview_list-side-box-margin">
                            <Col xs={0} sm={0} md={0} lg={3} xl={3}
                                 className="interview_list-side-box">
                                <Container className="interview_list-side-text">
                                    <h3 className="interview_list-side-day">{getMonthNameAndDay(v.date)}</h3>
                                    <br/>
                                    <h6>{v.title}</h6>
                                </Container>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                                <Image fluid src={v.image || defaultImageRetangular} style={{width: '200%'}} alt={v.image_legend} />
                            </Col>
                        </Row>
                    </Link>
                ))}
            </>
        )
    }

    function renderFirst() {
        return (
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12} key={itemsList[0].id}
                     className="interview_list-side-box-margin">
                    <Link to={link_to + currentYear + '/' + itemsList[0].id}>
                        <Image fluid src={itemsList[0].image}
                               alt={itemsList[0].alt_image} style={{width: '200%'}}/>
                    </Link>
                </Col>
            </Row>
        )
    }

    function incomplete() {
        return (
            <>
                {renderFirst()}
                {renderSlat()}
                <Row>
                    {itemsList.slice(slat, itemsList.length).map(item => (
                        renderItem(item)
                    ))}
                </Row>
            </>
        )
    }

    function complete() {
        return (
            <Row>
                {itemsList.map(item => (
                    renderItem(item)
                ))}
            </Row>
        )
    }

    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container>
                    {itemsList.length > 0 && itemsList.length % 12 === 0 ?
                        complete()
                        :
                        incomplete()
                    }
                </Container>
            </Col>
        </Row>
    );
}

export default InterviewsList;
