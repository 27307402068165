import React, {useEffect, useState} from 'react';
import {Carousel, Col, Container, Image, Row} from 'react-bootstrap';
import {Helmet} from "react-helmet-async";
import iconeVideos from '../../ui/imgs/iconeVIDEOS.png';
import Slider from "react-slick";

import getData from '../../services.js';
import LoadingScreen from "../../components/loading/loading";
import './home.css'
import {Link} from "react-router-dom";
import {getYearMonthByline} from "../../ui/utils/date";
import Ops from "../cases/ops";

const Home = () => {
    //Data
    const [dataHomeSS, setDataHomeSS] = useState([]);
    const [dataHomeNews, setDataHomeNews] = useState([]);
    const [dataHomeGlossary, setDataHomeGlossary] = useState(undefined);
    const [dataHomeInterviews, setDataHomeInterviews] = useState(undefined);
    const [dataHomeNewspapers, setDataHomeNewspapers] = useState([]);
    const [dataHomeDocumentary, setDataHomeDocumentary] = useState([]);
    //Environment
    const [ops, setOps] = useState(0);
    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        focusOnSelect: true,
        rtl: false,
        slidesToShow: 4,
        slidesToScroll: 3,
        initialSlide: 0,

        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
            }
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }, {
            breakpoint: 280,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        }]
    };

    //Effects
    useEffect(function getSlideshow() {
        getData('home/slideshow/').then((resp) => {
            setDataHomeSS(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, []);

    useEffect(function getHomeData() {
        getData('home/news/').then((resp) => {
            setDataHomeNews(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
        getData('home/glossary/').then((resp) => {
            setDataHomeGlossary(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
        getData('home/interviews/').then((resp) => {
            setDataHomeInterviews(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
        getData('home/newspapers/').then((resp) => {
            setDataHomeNewspapers(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
        getData('home/documentary/').then((resp) => {
            setDataHomeDocumentary(resp);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, []);

    useEffect(function updateTitle() {
        document.title = "OAPS - Página Principal";
    }, []);

    // function cutAndPrint(title) {
    //     //TODO: Sombra esta fora da imagem
    //     if (title && title.length > 70) {
    //         let cutTitle = title.slice(0, 70);
    //         return <div className="home-legend home-legend-to-show home-legend-size-cut">{cutTitle + "..."}</div>
    //     } else {
    //         return <div className="home-legend home-legend-to-show home-legend-size-full">{title}</div>
    //     }
    //
    // }

    //Functions
    function printSSItem(ss) {
        if (ss.model === 'event') {
            return (
                <Carousel.Item key={ss.id}>
                    <Link to={'evento/' + getYearMonthByline(ss.date) + '/' + ss.id}>
                        <Image
                            className="d-block w-100"
                            src={ss.image}
                            alt={ss.image_legend}
                        />
                    </Link>
                </Carousel.Item>
            )
        } else {
            let base = ss.model === 'news' ? "noticia" : "debate"
            return (
                <Carousel.Item key={ss.id}>
                    <Link to={base + '/' + ss.id}>
                        <Image
                            className="d-block w-100"
                            src={ss.image}
                            alt={ss.image_legend}
                        />
                    </Link>
                    <Carousel.Caption>
                        {/*<div><b>{ss.title}</b></div>*/}
                    </Carousel.Caption>
                </Carousel.Item>
            )
        }
    }

    function slideshow() {
        return (
            <Row className="home-box">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Carousel pause='hover'>
                        {dataHomeSS !== undefined && dataHomeSS.length > 0 && dataHomeSS.map(ss => (
                            printSSItem(ss)
                        ))}
                    </Carousel>
                </Col>
            </Row>
        )
    }

    function noticias() {
        return (
            <Row>
                {dataHomeNews !== undefined && dataHomeNews.length > 0 && dataHomeNews.map(news => (
                    <Col key={news.id} xs={12} sm={12} md={6} lg={6} xl={6} className="home-box">
                        <Link to={"/noticia/" + news.id}>
                            <div className="home-legend-to-hover">
                                <Image fluid src={news.image} alt={news.image_legend}/>
                                {/*<div className="home-legend home-legend-to-show">{news.title}</div>*/}
                                {/*{cutAndPrint(news.title)}*/}
                            </div>
                        </Link>
                    </Col>
                ))}
            </Row>
        )

    }

    function taNaMidia() {
        return (
            <Row>
                {/*Glossário*/}
                <Col xs={12} sm={12} md={6} lg={3} xl={3} className="home-box">
                    <a href={dataHomeGlossary.file}>
                        <div className="home-legend-to-hover">
                            <Image fluid src={dataHomeGlossary.image} style={{width: "200%"}}
                                   alt={dataHomeGlossary.image_legend}/>
                            {/*{cutAndPrint(dataHomeGlossary.title)}*/}
                        </div>
                    </a>
                </Col>
                {/*Entrevistas*/}
                <Col xs={12} sm={12} md={6} lg={3} xl={3} className="home-box">
                    <Link to={'/entrevista/' + dataHomeInterviews.date.split('-')[0] + '/' + dataHomeInterviews.id}>
                        <div className="home-legend-to-hover">
                            <Image fluid src={dataHomeInterviews.image_first_page} style={{width: "200%"}}
                                   alt={dataHomeInterviews.image_legend}/>
                            {/*{cutAndPrint(dataHomeInterviews.title)}*/}
                        </div>
                    </Link>
                </Col>
                {/*Tá na Midia*/}
                {dataHomeNewspapers.map(npaper => (
                    <Col key={npaper.id} xs={12} sm={12} md={6} lg={3} xl={3} className="home-box">
                        <a href={npaper.url} target="_blank" rel="noreferrer">
                            <div className="home-legend-to-hover">
                                <Image fluid src={npaper.image} style={{width: "200%"}} alt={npaper.title}/>
                                {/*{cutAndPrint(npaper.title)}*/}
                            </div>
                        </a>
                    </Col>
                ))}
            </Row>
        )
    }

    /*TODO: O passador está pequeno*/

    function documentarios() {
        return (
            <>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="documentary_section-name"><b>Documentários</b></div>
                        <Slider className="documentary-grid" {...settings}>
                            {dataHomeDocumentary.length > 0 && dataHomeDocumentary.map((vid) => (
                                <Col key={vid.id} className='documentary'>
                                    <a target='_blank' rel="noreferrer" href={vid.url}>
                                        <Container>
                                            <Row>
                                                <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                                    <Image fluid src={iconeVideos}/>
                                                </Col>
                                                <Col xs={10} sm={10} md={10} lg={10} xl={10}
                                                     className="documentary-name">
                                                    <div className={"no-margin"}>{vid.small_title}</div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </a>
                                </Col>
                            ))}
                        </Slider>
                    </Col>
                </Row>
            </>
        )
    }

    function container() {
        return (
            <>
                <Helmet>
                    <meta property="og:title" content="OAPS: Home"/>
                    <meta property="og:description" content='Página Principal'/>
                    <meta property="og:image" content="https://observatorio.analisepoliticaemsaude.org/logo.png"/>
                    <meta property="og:url" content="https://observatorio.analisepoliticaemsaude.org/"/>
                </Helmet>
                <Container>
                    <br/>
                    {slideshow()}
                    {noticias()}
                    {taNaMidia()}
                    <Row className='blue-line-section-separator'/>
                    {documentarios()}
                </Container>
            </>
        )
    }

    return (
        <>
            {ops === 0 && dataHomeSS.length > 0 && dataHomeDocumentary.length > 0 && dataHomeInterviews !== undefined
                 && dataHomeGlossary !== undefined ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    );
}

export default Home;
