import React from 'react';
import {Col, Container, Image, Row} from 'react-bootstrap';
import img_rodape from './imgs/rodape.png';

const Rodape = () => {
    return (
        <footer className='center text'>
            <div className='linha-cinza'/>
            <Container>
                {/*Imagem do rodape*/}
                <Row>
                    <Col>
                        <br/>
                        <Image src={img_rodape} fluid/>
                        <br/>
                        <br/>
                    </Col>
                </Row>
                {/*Informações da UFBA, ISC e Observatorio*/}
                <Row>
                    <Col>
                        <p className='paragrafo-rodape'>Instituto de Saúde Coletiva • Universidade Federal da Bahia • Rua Basílio da Gama, s/n • Campus
                        Universitário do Canela • 40.110-040 • Salvador-Bahia • +55 71 3283-7441 / 3283-7442</p>
                        <p className='paragrafo-rodape'>© {new Date().getFullYear()} Observatório de Análise Política em Saúde. Todos os direitos reservados.</p>
                    </Col>
                </Row>
            </Container>
            <br/>
            <br/>
            <br/>
        </footer>
    );
};

export default Rodape;
