import React, {useState} from 'react';
import {Button, Col, Container, Image, Row, Spinner} from 'react-bootstrap';
import {cadastroBoletim} from '../../services.js';
import {useFormik} from 'formik';
import Seta from '../../ui/imgs/icones/seta.png';
import {validate} from '../../ui/utils/validate.js'
import './subscriptionForm.css'

const SubscriptionForm = () => {
    //Data
    const [mostrar, setMostrar] = useState(false);
    const [load, setLoad] = useState(false);
    //Environment
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            institution: '',
            occupation: '',
        },
        validate,
        onSubmit: value => {
            setLoad(true);
            cadastroBoletim(value).then(async result => {
                let a = await result.json();
                setLoad(false);
                alert(a.message);

                if (result.ok && result.status !== 203) {
                    formik.resetForm();
                }

                return a;
            });
        },
    });

    //Functions
    function renderInscricao() {
        return (
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Container className="bulletin_subscription-box pt-3 pb-2 mb-2">
                        <Row className="bulletin_subscription-text">
                            <b>CADASTRE-SE E RECEBA NOSSO BOLETIM</b>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <form hidden={load} onSubmit={formik.handleSubmit}>
                                    <Container className='bulletin_subscription-container'>
                                        <Row>
                                            <Col xs={12} md={6} sm={6}>
                                                <input className='bulletin_subscription-text-fild m-1' id="name" name="name"
                                                       placeholder="Nome:*" type="text" onChange={formik.handleChange}
                                                       value={formik.values.name}
                                                />
                                                {formik.errors.name ?
                                                    <label className='pl-4'>{formik.errors.name}</label> : null}
                                            </Col>
                                            <Col xs={12} md={6} sm={6}>
                                                <input className='bulletin_subscription-text-fild m-1' id="email" name="email"
                                                       placeholder="E-mail:*" type="email" onChange={formik.handleChange}
                                                       value={formik.values.email}
                                                />
                                                {formik.errors.email ?
                                                    <label className='pl-4'>{formik.errors.email}</label> : null}
                                            </Col>
                                        </Row>
                                        <Row>
                                            {!mostrar &&
                                                <Col className='bulletin_subscription-indicator mouse' onClick={() => setMostrar(!mostrar)}>
                                                    <Image src={Seta} fluid/>
                                                </Col>
                                            }
                                        </Row>
                                        {mostrar &&
                                            <>
                                                <Row>
                                                    <Col xs={12} md={6} sm={6}>
                                                        <input className='bulletin_subscription-text-fild m-1' id="institution" name="institution"
                                                               placeholder="Instituição:" type="text" onChange={formik.handleChange}
                                                               value={formik.values.institution}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6} sm={6}>
                                                        <input className='bulletin_subscription-text-fild m-1' id="occupation"
                                                               name="occupation" placeholder="Ocupação:"
                                                               onChange={formik.handleChange} value={formik.values.occupation}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                        }

                                            <Row>
                                                <Col>
                                                </Col>
                                                <Col xs={4} md={3} sm={3}>
                                                    <Button className='w-100'
                                                            onClick={formik.handleSubmit}>Enviar</Button>
                                                </Col>
                                            </Row>
                                        <Row>
                                            {mostrar &&
                                                <Col className='bulletin_subscription-indicator bulletin_subscription-turn-indicator generic_mousePointer'
                                                     onClick={() => {
                                                         setMostrar(!mostrar);
                                                         formik.resetForm();
                                                     }}>
                                                    <Image src={Seta} fluid/>
                                                </Col>
                                            }
                                        </Row>
                                    </Container>
                                </form>
                                <Spinner hidden={!load} animation="grow" size="md" className="spinner-center bulletin_subscription-margin"/>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        )
    }

    return (
        renderInscricao()
    )
}

export default SubscriptionForm;
