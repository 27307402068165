import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import {confirmaBoletim} from '../../services.js';
import PageBreadCrumb from "../../components/BreadCrumb/breadcrumbs";
import LoadingScreen from "../../components/loading/loading";
import Ops from "../cases/ops";

const Unsubscribe = ({match}) => {
    //Data
    const [confirm, setConfirm] = useState(undefined);
    const [ok, setok] = useState(true);
    //Environment
    const [ops, setOps] = useState(0);
    const breadcrumbs = ({
        "Pages": [{
            "title": "BOLETINS",
            "link": "/boletins/",
            "active": false
        }, {
            "title": "Descadastrar",
            "link": "",
            "active": true
        }]
    });

    //Effects
    useEffect(function userUnsubscribe() {
        confirmaBoletim(false, match.params.id).then(async result => {
            let resposta = await result.json();
            setConfirm(resposta);
            setok(result.ok);
        }).catch((err) => {
            console.log(err.message);
            setOps(1)
        });
    }, [match.params.id]);

    useEffect(function titleUpdate() {
        document.title = "OAPS - Desinscrever do Boletim";
    }, []);

    //Functions
    function container() {
        return (
            <Container className='fundo-branco other-page'>
                <Row>
                    <Col xs={12} md={12} sm={12}>
                        <h1>Atenção</h1>
                    </Col>
                    {ok ?
                        <>
                            <Col xs={12} md={12} sm={12}>
                                Olá {confirm[1]}!
                            </Col>
                            <Col xs={12} md={12} sm={12}>
                                O e-mail: {confirm[0]} foi descadastrado da nossa base, jutamente com todos os seus
                                dados.
                            </Col>
                            <Col xs={12} md={12} sm={12}>
                                Você não mais receberá nossos boletins. Caso deseje retornar a receber os boletins
                                do Observatório favor se inscrever.
                            </Col>
                        </>
                        :
                        <Col className='centralizar color-red' xs={12} md={12} sm={12}>
                            Erro: {confirm.message}
                        </Col>
                    }
                </Row>
            </Container>
        )
    }

    return (
        <>
            <PageBreadCrumb pages={breadcrumbs}/>
            {ops === 0 && confirm !== undefined ?
                container()
                :
                ops === 0 ?
                    <LoadingScreen/>
                    :
                    <Ops/>
            }
        </>
    )
}

export default Unsubscribe;
