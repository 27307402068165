import React from 'react';
import {Col, Container, Image, Media, Row} from 'react-bootstrap';
import imagem from "../../ui/imgs/icones/comment.png";
import {formataData} from "../../ui/utils/date";

const CommentsList = ({comments}) => {
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Container fluid>
                    <ul className="list-unstyled">
                        {comments !== undefined && comments.length > 0 && comments.map(comment => (
                            <Media key={comment.author} as="li">
                                <Image width={64} height={64} className="mr-3" src={imagem} alt="Generic placeholder" />
                                <br/>
                                <Media.Body>
                                    <h5>{comment.author} em {formataData(comment.created_at)}</h5>
                                    <h6>{comment.body}</h6>
                                    <br/>
                                </Media.Body>
                            </Media>
                        ))}
                    </ul>
                </Container>
            </Col>
        </Row>
    );
}

export default CommentsList;
