import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Col, Container, Row} from 'react-bootstrap';
import PageBreadCrumb from '../../components/BreadCrumb/breadcrumbs';
import './cases.css'

const Miss = () => {
    //Environment
    const breadcrumbs = ({
        "Pages": [{
            "title": "Página Não Encontrada",
            "link": "/404",
            "active": true
        }]
    });

    //Effects
    useEffect(() => {
        document.title = "OAPS - 404";
    }, []);

    return (
        <>
            <Row>
                <Col xs={12} md={12} sm={12}>
                    <PageBreadCrumb pages={breadcrumbs}/>
                </Col>
            </Row>
            <Container className='cases-background-white-color cases-other-page'>
                <Row>
                    <Col className='text-center' xs={12} md={12} sm={12}>
                        <h1>404</h1>
                    </Col>
                    <Col className='text-center' xs={12} md={12} sm={12}>
                        <h5>Página não encontrada.</h5>
                    </Col>
                    <Col className='text-center' xs={12} md={12} sm={12}>
                        Voltar para a <Link to={'/'}>Home</Link>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Miss;
